@import '../../assets/styles/mixins';
@import '../../assets/styles/mixins';

.title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 75%;
    margin: auto auto 40px;

    @include min-hd {
        max-width: 100%;
    }

    h2 {
        width: -webkit-fill-available;
        text-align: center;
        margin-bottom: 0;
        color: #21B193;
        font-size: 40px;
        font-weight: bold;

        @include mobile {
            width: 750px;
            font-size: 30px;
        }

        @include exsmall {
            font-size: 22px;
        }
    }
}