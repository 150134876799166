.fileswrapper {
    .fileItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        flex-grow: 1;
        position: relative;

        .fileIcon {
            margin-right: 10px;
            font-size: 24px;
            flex-shrink: 0;
        }

        .fileName {
            flex-grow: 1;
            margin-right: 10px;
            text-align: center;
        }

        .fileInput {
            margin-right: 10px;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 150px;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            background-color: red;
            border-radius: 25px;
            transform: translateY(-50%) translateX(50%);
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .removeIcon {
            cursor: pointer;
            color: rgb(255, 255, 255);
            padding: 10%;
        }

        .fileEditIcon {
            font-size: 18px;
            color: #0532d3;
            cursor: pointer;
            margin-left: 10px;
            margin-right: 20px;
        }
    }
}