@import "../../assets/styles/mixins";


.ZoomerWrapper {
    .VoicerPrompt {
        bottom: 0;
        right: 0;
        background-color: rgb(255, 255, 255);
        background-image: none;
        border-top-left-radius: 11px;
        box-shadow: rgba(88, 88, 88, 0.35) 0px 0px 22px 0px;
        color: rgb(17, 17, 17);
        line-height: 35.2px;
        padding: 20px;
        position: fixed;
        text-size-adjust: 100%;
        max-width: 500px;
        z-index: 100;
        -webkit-font-smoothing: antialiased;

        p {
            font-size: 22px;
            margin: unset;
            text-align: center;
        }
    }

    .ZoomerBlock {
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 100;

        .ZoomerInfo {
            text-align: center;
            background: #1d2c3b;
            color: #fff;
            font-weight: bold;
            height: 37px;
            width: 100%;
            box-shadow: 0px 0px 5px 1px #55555573;

            .a1 {
                font-size: 7px;
            }

            .a2 {
                font-size: 11px;
            }

            .a3 {
                font-size: 15px;
            }
        }

        .ZoomerToolbar {
            padding: 3px 5px;
            background: #fff;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 0px 0px 5px 1px #55555573;
            -webkit-transform: translateY(-12px);
            -ms-transform: translateY(-12px);
            transform: translateY(-12px);

            .ZoomerIcon {
                cursor: pointer;
                font-weight: bold;
                border-radius: 18px;
                font-size: 18px;
                padding: 0px 6px;
                transition: 0.2s ease;
                background: #4598d4;
                color: #fff;
                vertical-align: middle;
                margin: 3px 1px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-align: center;
                height: 25px;

                &:hover {
                    background: #2383c8;
                }
            }
        }
    }
}