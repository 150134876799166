@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.homeBanner {
    position: relative;
    z-index: 99;
    background-color: var(--primary-color);

    .animWrapper {
        position: relative;
        width: 100%;
        height: 70vh;
        overflow: hidden;

        @include laptop {}

        @include ipadpro {}

        @include ipad {}

        @include mobile {
            height: auto;
        }

        @include small {}

        @include mobileLand {}


        .downArrow {
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translateX(-50%);
        }


        /*Newly added*/

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mobile {
            display: none;

            @include mobile {
                display: block;
            }
        }

        .desktop {
            display: block;

            @include mobile {
                display: none;
            }
        }
    }

    .bannerCntWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        text-align: center;

        .titleWrapper {
            width: 50%;
            text-align: left;
            margin-bottom: 5vh;

            .headline {
                font-size: 2.4em;
                color: #fff;
                margin-bottom: 0.2em;
                font-weight: bold;

                @include uhd {
                    font-size: 2.7em;
                }

                @include laptop {
                    font-size: 2.2em;
                }

                @include ipadpro {
                    font-size: 2.2em;
                }

                @include ipad {
                    font-size: 2.7em;
                }

                @include mobile {
                    font-size: 2.4em;
                }

                @include small {
                    font-size: 1.5em;
                }

                @include xxsmall {
                    font-size: 1.5em;
                }
            }

            @include ipad {
                width: 75%;
            }

            @include mobile {
                width: 100%;
                margin-bottom: 25px;
            }

            @include xxsmall {
                width: 100%;
            }
        }

        .cnt {
            font-size: 1.2em;
            color: #fff;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 1.1em;
            }

            @include ipad {
                font-size: 1em;
            }
        }

        @include mobile {
            position: unset;
            transform: translate(0%, 0%);
            background-color: var(--primary-color);
            padding: 25px;
        }
    }
}