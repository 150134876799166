.Toastify .Toastify__toast {
    border-radius: 10px;
    padding: 8px 16px 8px 8px;
}

.Toastify .Toastify__close-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #bbbbbb;
    margin: auto;
    opacity: 0.7;
}

.Toastify .Toastify__close-button svg {
    width: 16px;
    height: 22px;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
.fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation fade-out-right
 * ----------------------------------------
 */
.fade-out-right {
    -webkit-animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}

@keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}