@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';



.passwordResetForm {

    @include mobile {
        margin-top: 25px;
    }

    display: flex;
    justify-content: center;

    .contentWrapper {
        // width: 100%;    

        .header {
            font-weight: 600;
            text-align: center;

            h2 {
                margin: 0;
            }
        }

        .passwordFields {
            .inputs {
                margin: 25px 0;

                .fieldWrap {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    @include small {
                        display: block;
                    }

                    .fieldWrapper{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .labelBox {
                        margin-right: 20px;

                        @include small {
                            margin-right: 0;
                        }
                    }

                    input {
                        border-radius: 5px;
                        border: 1px solid var(--primary-color)
                    }

                    button {
                        background-color: var(--primary-color);
                        border: 0;
                        border-radius: 30px;
                    }

                    .eyeButton {
                        margin: 0 5px;
                        color: var(--primary-color);
                    }
                }
            }

            .buttonWrapper {
                display: flex;
                justify-content: center;

                button {
                    background-color: var(--primary-color);
                    padding: 10px 30px;
                    border-radius: 50px;
                    border: 1px solid var(--primary-color);
                    color: #ffffff;

                    &:hover {
                        background-color: #ffffff;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}