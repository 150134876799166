@import '../../assets/styles/mixins';



.perk {
    text-align: center;
    background-color: #fff;
    flex-basis: 23.3%;
    max-width: 23.3%;
    display: flex;
    padding: 35px 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    @include ipadpro {
        padding: 25px 10px;
    }

    @include ipad {
        flex-basis: 30%;
        max-width: 30%;
    }

    @include mobile {
        flex-basis: 45%;
        max-width: 45%;
    }

    svg {
        color: #2d87ef;
    }

    h4 {
        margin-bottom: 0px;

    }

    img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 0.5em;

        @include ipadpro {
            width: 60px;
            height: 60px;
        }

        @include ipad {
            width: 60px;
            height: 60px;
        }

        @include mobile {
            width: 55px;
            height: 55px;
        }
    }

    .perkTextContent {
        display: flex;
        color: #3B60BC;
        text-align: left;
        align-items: center;
    }

    span {
        margin-top: 5px;
        font-weight: bold;
        display: block;
    }
}

.ProfileWrrapper {
    padding: 0;

    a {
        padding: 35px 10px;
        background: none;
        border: none;
        color: inherit;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    span {
        margin-top: 0px;
        display: block;
    }

    img {
        max-height: 80px;
        width: 100%;
        object-fit: contain;
    }
}