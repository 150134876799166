@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.inputWarp {
    background-color: #DAE9F8;
    border: 2px solid #DAE9F8;
    border-radius: 50px;
    width: 100%;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;

    &.inputFocus {
        border-color: var(--primary-color);
        background-color: #fff;
    }

    &.inputError {
        border-color: $lgbt-color-red;
        background-color: #fff;

        .errorIcon {
            display: block;
        }
    }

    input {
        border: none;
        outline: none;
        background-color: #00000000;
        width: 100%;
        padding: 0.8em 0 0.8em 1em;
    }

    .errorIcon {
        display: none;
        margin-left: 1em;
        color: $lgbt-color-red;
        width: 1.8em;
        height: 1.8em;
        margin-right: 10px;
    }
}