@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';


.imageContentWrap {
    background-color: var(--image_section_bg_color);

    .imageContentBlock {
        display: flex;
        padding: 80px 0;

        @include mobile {
            padding: 40px 0;
        }

        ul {
            padding-left: 20px;

        }

        ul li {
            list-style: disc;
        }

        .contents {
            color: var(--image_section_text_color);

            .contentBlock {
                font-size: 20px;
                line-height: 1.7;
                color: var(--image_section_text_color);

                li {
                    list-style: disc;
                    margin-left: 20px;
                }
            }
            p {
                margin: 0 !important; 
                padding-bottom: 1rem;
                &:last-of-type {
                    padding-bottom: 0px !important;
                }
            }
            
            h2 {
                margin: 0 !important; 
                padding: 0.84rem 0;
            }
        }

        .imgBlock {
            @include mobile {
                margin-top: 50px;
            }

        }
        .imageRows{
            @include mobile{
                flex-direction: column-reverse !important;
            }
        }
    }

    a {
        color: var(--link_text_color) !important;
        text-decoration: underline !important;
    }
}
