@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    top: 50%;
    left: 50%;

    .dot {
        margin: 0px 1px;
        width: 12px;
        height: 12px;
        background-color: var(--primary-color);
        border-radius: 50%;
        animation: dotSpin 1.2s infinite ease-in-out;
    }

    .dot:nth-child(1) {
        animation-delay: -0.8s;
    }

    .dot:nth-child(2) {
        animation-delay: -0.6s;
    }

    .dot:nth-child(3) {
        animation-delay: -0.4s;
    }

    .dot:nth-child(4) {
        animation-delay: -0.2s;
    }
}

@keyframes dotSpin {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}