@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.jobPostForm {
    padding: 4*$ref-size 0;

    .fieldGroupWrap {
        padding: 2*$ref-size 0;
        border-bottom: 1px solid #aaa;
        border-bottom: 1px solid #aaa;

        .fieldHead {
            h2 {
                margin: 0;
                color: var(--primary-color);
                font-weight: bold;
                margin-bottom: 1.5em;
            }
        }

        .fieldWrap {
            margin-bottom: 1.5*$ref-size;
            position: relative;
            .labelBox{
                display: flex;
                justify-content: space-between;
                span{
                    font-size: 0.8em;
                    color: #696969;
                    font-style: italic;
                }
            }

            .inputWrapper{
                display: flex;
            }

            input,
            textarea,
            select {
                width: 100%;
                border: 1px solid;
                outline: none;
                border-radius: 5px;
                padding: 10px 15px;
            }
            .selectBox{
                input{
                    padding: 7px 20px;
                }
            }
        }

        .salaryAmount {
            display: flex;
            justify-content: flex-start;

            .minSalary {
                margin-right: 10px;

                @include mobile{
                    margin-bottom: 25px;
                }

                @include small{
                    margin-right: 0;
                }
            }

            @include small {
                display: block;
            }
            input[type=number]::-webkit-outer-spin-button,
            input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
             margin: 0;
            }
        }
    }

    .submitBtn {
        margin-top: 2*$ref-size;
        display: flex;
        justify-content: flex-end;

        @include small {
            flex-wrap: wrap;
        }

        button {
            margin-left: auto;
            color: #fff;
            background-color: var(--complementary-color);
            border: 2px solid var(--complementary-color);
            padding: 12px 25px;
            border-radius: 30px;
            font-size: 1em;
            display: inline-block;
            transition: 0.3s ease-in-out;
            text-align: center;

            @include small {
                margin: 6px;
            }

            &:disabled{
                background-color: #ccc;
                border-color: #ccc;
            }
        }
    }
}



.jobDetails{
    .error_message{
        border: 1px solid #F09090;
        background-color: #FDEAEA;
        border-radius: 5px;
        padding: 5px 10px;
        margin-top: 1em;
        position: relative;
    }
    p{
        margin: 0;
        font-size: 0.8em;
        font-style: italic;
    }
}
