$primary-blue-color: #365BB8;
$lgbt-color-red: #F91F19;
$lgbt-color-orange: #FF8B01;
$lgbt-color-yellow: #FEE800;
$lgbt-color-green: #008143;
$lgbt-color-blue: #0064AA;
$lgbt-color-contract-blue: #133FA7;
$lgbt-color-magenta: magenta;
$lgbt-color-purple: #8E31C9;
$lgbt-color-purple2: #9D11A0;
$lgbt-color-violet: #492BD0;
$light-shade:rgb(225, 225, 238);
// Resolutions
$UHD-width: 3840px;
$hd-width: 1600px;
$mini-hd-width: 1600px;
$laptop-width: 1400px;
$ipadpro-width: 1200px;
$ipad-width: 992px;
$mobile-width: 768px;
$mobile-small-width: 576px;
$mobile-ex-small-width: 435px;
$mobile-2x-small-width: 320px;
$mobileland-width: 991px;
$mobileland-height: 576px;

$ref-size: 18px;

@media (max-width: $ipadpro-width) {
    $ref-size: 16px;
}

@media (max-width: $mobile-width) {
    $ref-size: 14px;
}
