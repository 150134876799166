@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.clientListing {
    background-color: #F9F9F9;
    padding: 100px 0px;

    @include mobile {
        padding: 20px 0px;
    }
}

.listingWrapper {
    list-style: none;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    -ms-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0;

    @include laptop {
        grid-template-columns: repeat(4, 1fr);
    }

    @include ipadpro {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
        font-size: 24px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    @include mobile {
        margin-bottom: 40px;
    }

    @include small {
        margin-bottom: 30px;
    }

    @include exsmall {
        margin-bottom: 20px;
    }

    .logoWrapper {

        height: 100px;
        width: 240px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffff;

        &:hover {
            border: 1px solid var(--primary-color);
        }

        img {
            max-width: 80%;
            max-height: 80px;
            display: block;
            margin: auto;
        }

        @include ipad {
            height: 100px;
            width: 220px;
        }

        @include small {
            height: 89px;
            width: 180px;
        }

        @include exsmall {
            height: 80px;
            width: 120px;
        }

        @include xxsmall {
            height: 60px;
            width: 120px;
        }
    }
}