.fieldError {
    border: 1px solid #F09090;
    background-color: #FDEAEA;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 1em;
    position: relative;
    &::before{
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        top: -8px;
        left: 18px;
        background-color: #FDEAEA;
        border-top: 1px solid #F09090;
        border-left: 1px solid #F09090;
        transform: rotateZ(45deg);
        border-top-left-radius: 4px;
    }
    p{
        margin: 0;
        font-size: 0.8em;
        font-style: italic;
    }
}