@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.cardWrapper {
    background-color: #F9F9F9;

    .card {
        background-color: #fff;
        text-align: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        border-radius: 11px;
        padding: 50px 0;
        display: flex;
        justify-content: center;
        margin-top: -100px;
        justify-content: center;
        position: relative;
        overflow: hidden;

        .contentWrapper {
            width: 90%;

            .stripe {
                position: absolute;

                @include ipad {
                    width: 10%;
                }

                @include small {
                    width: 9%;
                }
            }

            .top {
                transform: scaleX(-1) scaleY(-1);
                left: 0;
                top: -3%;
            }

            .bottom {
                right: 0;
                bottom: -3%;
            }

            h1 {
                color: var(--primary-color);
                font-size: 60px;
            }

            p {
                font-size: 20px;
            }
        }

        @include small {
            width: 96%;
            padding: 0px;

            h1 {
                margin: 10px 0;
                font-size: 40px;
            }
        }

        @include exsmall {
            margin-top: -50px;
        }
    }
}