@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/variables';

$primary-color: #4CAF50;

.dropzone {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: border-color 0.3s;
    cursor: pointer;

    p {
        margin: 0;
        color: #666;
    }
    .error {
        color: red;
        margin-top: 10px;
    }
}

.fileList {

    .documentPreview {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        gap: 10px;
    }

}

.filemessage {
    p {
        font-size: 16px;
        font-weight: 700;
        color: gray;
    }
}
