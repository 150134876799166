@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.nameFieldWrapper {
    padding: 36px 0;
    border-bottom: 1px solid #aaa;
    justify-content: end;

    .title {
        h2 {
            color: var(--primary-color);
            margin: 0 0 1.5em 0;
            font-weight: bold;
            font-size: 1.5em;
        }
    }

    .inputWrapper {
        .fieldWrap {}

        input {
            width: 100%;
            border: 1px solid;
            outline: none;
            border-radius: 5px;
            padding: 10px 15px;
        }
    }
    .email{
        margin-top: 27px;
    }
}