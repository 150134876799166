@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.teaserWrapper {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    clip-path: ellipse(1400px 100% at 50% 100%);
    padding: (8*$ref-size) 0 (6 * $ref-size);

    @include laptop {
        clip-path: ellipse(1200px 100% at 50% 100%);
    }

    @include ipadpro {
        padding: (6*$ref-size) 0 (4 * $ref-size);
        clip-path: ellipse(1000px 100% at 50% 100%);
    }

    @include ipad {
        padding: (5.5*$ref-size) 0 (3.5 * $ref-size);
        clip-path: ellipse(900px 100% at 50% 100%);
    }

    @include mobile {
        padding: (4.5*$ref-size) 0 (3 * $ref-size);
        clip-path: ellipse(800px 100% at 50% 100%);
    }

    @include small {
        clip-path: ellipse(700px 100% at 50% 100%);
    }

    .sideStrips {
        position: absolute;
        top: 0;
        right: 0%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .teaser {
        * {
            text-align: center;
        }

        h1 {
            color: var(--primary-color);
        }

        .teaserCnt {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: var(--primary-color);
                margin-right: 5rem;
                margin-bottom: 0;

                @include ipadpro {
                    margin-bottom: 1em;
                }

                @include ipad {
                    margin-right: 0;
                }
            }

            @include ipad {
                flex-direction: column;
            }
        }
    }
}