@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.cardParent {
    &::after {
        content: '';
        position: absolute;
        background-color: #F9F9F9;
        height: 50%;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: -1;
    }

    .cardWrapper {
        position: relative;
        z-index: 0;


        .container {
            padding: 0px;

            h1 {
                font-size: 60px;
                margin-top: 0px;
                margin-bottom: 30px;

                @include ipad {
                    margin-bottom: 20px;
                }

                @include mobile {
                    font-size: 40px;
                }

                @include exsmall {
                    font-size: 35px;
                    margin-bottom: 15px;
                }
            }

            p {
                font-size: 20px;

                @include mobile {
                    font-size: 18px;
                }

                @include exsmall {
                    font-size: 15px;
                }
            }

            background-color: #ffff;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
            border-radius: 20px;

            .cardTop {
                background-color: var(--primary-color);
                color: #ffff;
                padding: 46px 220px;
                border-radius: 20px 20px 0px 0px;
                text-align: center;
                margin-top: -100px;
                position: relative;

                @include laptop {
                    padding: 20px 50px;
                }

                @include ipadpro {
                    padding: 10px 20px;
                }

                @include ipad {
                    margin-top: -75px;
                }

                @include mobile {
                    margin-top: -150px;
                }

                @include small {
                    padding: 10px;
                    margin-top: -100px;
                }
            }

            .cardContents {
                border-radius: 20px;
                padding: 60px 120px 130px 120px;

                .cardMiddle {
                    margin: 35px 0px 0px 0px;

                    .topLeft {
                        padding-right: 30px;
                        padding-left: 0px;

                        p {
                            color: #333333;
                        }

                        @include ipad {
                            padding: 0px;
                            margin: 0px;
                        }
                    }

                    .topRight {
                        padding-left: 30px;

                        p {
                            color: #333333;
                        }

                        @include ipad {
                            padding: 0px;
                            margin: 30px 0 0 0;
                        }
                    }
                }

                @include ipadpro {
                    padding: 50px 35px 100px 35px;
                }

                @include ipad {
                    padding: 25px 20px 25px 20px;
                }

                @include xxsmall {
                    padding: 10px;
                }
            }
        }
    }
}