@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.profileWrapper {
    padding: 0 40px;

    h3 {
        text-align: center;
    }

    .error {
        color: #f00;
        margin-bottom: 0;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .input {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 40%;
            margin: 0.5rem 0;

            &:last-of-type {
                margin-bottom: 30px;
            }

            label {
                width: 100%;
                text-align: right;
                margin-right: 1rem;
                margin-top: 10px;
            }

            .inputWrapper {
                outline: none;
                width: 100%;

                input {
                    border-radius: 4px;
                    border: 1px solid #8b8b8b;
                    padding: 8px 10px;
                    outline: none;
                    width: 100%;
                }

                .error {
                    color: #f00;
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    font-style: italic;
                    color: #ff9102;
                }
            }
        }

        button {
            padding: 15px 35px;
            border-radius: 40px;
            margin-right: 0.5em;
            transition: 0.3s ease;
            display: inline-block;
            margin-bottom: 0.5em;
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
            color: #fff;
            transition: 0.2s ease;

            &:hover {
                color: var(--primary-color);
                background-color: #fff;
            }
        }
    }
}