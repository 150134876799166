@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.homeBanner {
    position: relative;
    z-index: 99;

    .animWrapper {
        position: relative;
        width: 100%;
        height: 88vh;
        overflow: hidden;
        background-color: var(--primary-color);
        clip-path: ellipse(1600px 100% at 50% 0px);

        @include laptop {
            clip-path: ellipse(1200px 100% at 50% 0px);
        }

        @include ipadpro {
            clip-path: ellipse(1000px 100% at 50% 0px);
        }

        @include ipad {
            clip-path: ellipse(900px 100% at 50% 0px);
        }

        @include mobile {
            clip-path: ellipse(800px 100% at 50% 0px);
            height: 75vh;
        }

        @include small {
            clip-path: ellipse(700px 100% at 50% 0px);
        }

        @include mobileLand {
            height: 155vh;
        }


        .downArrow {
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .bannerCntWrapper {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .headline {
            font-size: 2.7em;
            color: #fff;
            margin-bottom: 0.2em;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 2.5em;
            }

            @include ipad {
                font-size: 2.3em;
            }

            @include mobile {
                font-size: 2.2em;
            }

            @include xxsmall {
                font-size: 1.9em;
            }
        }

        .cnt {
            font-size: 1.2em;
            color: #fff;
            text-shadow: 0px 0px 20px #000000;
            margin-bottom: 5em;

            @include laptop {
                margin-bottom: 1em;
            }

            @include ipadpro {
                font-size: 1.1em;
            }

            @include ipad {
                font-size: 1em;
            }

            @include mobile {
                margin-bottom: 3em;
            }
        }
    }
}