@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.navWrapper {
    .navToggler {
        display: none;
        padding: 0;

        @include ipadpro {
            border: 0;
            outline: none;
            position: relative;
            height: 19px;
            width: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &::before,
            &::after {
                content: "";
                width: 100%;
                height: 3px;
                background-color: #000;
                border-radius: 2px;
            }

            .navTogglerIcon {
                display: block;
                width: 100%;
                height: 3px;
                background-color: #000;
                border-radius: 2px;
            }
        }
    }

    .navigationBar {
        display: flex;
        align-items: center;
        position: relative;

        .navClose {
            display: none;
        }

        @include ipadpro {
            transition: 0.5s cubic-bezier(0, 0, 0.3, 1);
            transform: translateX(100%);
            position: fixed;
            height: 100%;
            background: #fff;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 3.5em 2em 2em 2em;
            z-index: 999;

            .navClose {
                position: absolute;
                display: inline-block;
                top: 30px;
                right: 30px;

                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        @include small {
            align-items: flex-start;
            width: 80%;
        }

        .navLinks {
            display: flex;
            align-items: center;
            margin-right: 1em;

            @include ipadpro {
                display: block;
            }

            @include small {
                width: 100%;
                margin-right: 0;
            }

            .navItem {

                a {
                    height: 100%;
                    display: block;
                    padding: 2.5em 0.8em;
                    color: #000;
                    font-weight: bold;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        height: 0px;
                        background-color: var(--primary-color);
                        overflow: hidden;
                        transition: 0.3s ease;

                        @include ipadpro {
                            content: unset;
                        }

                        @include mobile {
                            margin: 0px auto 0px auto;
                        }
                    }

                    &:hover {
                        &::after {
                            height: 5px;
                        }
                    }

                    &.active {
                        &::after {
                            height: 5px;
                        }
                    }

                    @include min-hd {
                        padding: 2.5em 0.5em;
                    }


                    @include ipadpro {
                        padding: 20px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
                        font-size: 1.3em;

                        &.active {
                            &::after {
                                height: 0px;
                            }
                        }
                    }
                }

                &:last-child {
                    a {
                        @include ipadpro {
                            border-bottom: 0px;
                        }
                    }
                }

                .primaryColor {
                    color: var(--primary-color);
                }
            }
        }


        .authBtn {
            .signUp {
                @include ipad {
                    font-size: 1.2em;
                    float: right;
                }

                @include mobile {
                    padding: 14px 32px;
                    font-size: 1.3em;
                }

                @include xxsmall {
                    padding: 10px 15px;
                }
            }

            .logout {
                color: #fff;
                background-color: var(--primary-color);
                border: 2px solid var(--primary-color);
                padding: 12px 25px;
                border-radius: 30px;
                font-size: 1em;
                display: inline-block;
                transition: 0.3s ease-in-out;
                text-align: center;

                @include ipad {
                    font-size: 1.2em;
                    float: right;
                }

                @include mobile {
                    padding: 14px 32px;
                    font-size: 1.3em;
                }

                &:hover {
                    background-color: #fff;
                    color: var(--primary-color)
                }
            }

            @include ipadpro {
                margin-top: 40px;
            }
        }
    }
}