@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.promoSection {
    padding: 24vh 0 21vh;
    margin: -21vh 0;
    background-color: #F9F9F9;
    position: relative;
    z-index: 0;

    @include mobile{
        padding: 28vh 0 28vh;
    }

    .cloud1 {
        position: absolute;
        width: 235px;
        top: 100px;
        left: 32px;
        z-index: -1;
        @include laptop{
            top: 150px;
        }
    @include mobile{
            top: 220px;
        }
    }

    .cloud2 {
        position: absolute;
        width: 270px;
        top: 420px;
        left: 125px;
        z-index: -1;
        @include ipad{
            top: 350px;
        }
        @include mobile{
            top: 600px;
            left: 70px;
        }
    }

    .cloud3 {
        position: absolute;
        top: 270px;
        right: 160px;
        width: 250px;
        z-index: -1;
        @include laptop{
            top: 200px;
        }
        @include mobile{
            top: 450px;
            right: 60px;
        }
    }

    .cntWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 50%;
        margin: 0 auto;

        @include laptop{
            max-width: 65%;
        }
        @include ipadpro{
            max-width: 960px;
            padding-right: 1.5em;
            padding-left: 1.5em;
        }
        @include mobile{
            flex-direction: column;
        }

        .promoTitle {
            font-size: 2.5em;
            font-weight: bold;
            @include min-hd{
                font-size: 2.3em;
            }

            @include mobile{
                font-size: 2em;
                margin: 0 0 1em 0;
            }

            @include small{
                margin: 0 0 1.5em 0;
            }

            span {
                display: block;
                line-height: 1.3;
                @include mobile{
                    display: inline-block;
                    margin-right: 0.5em;
                }
                @include small{
                    display: block;
                    text-align: center;
                    margin-right: 0;
                }
            }

            .redText {
                color: var(--decor-color-2);
            }

            .orangeText {
                color: var(--decor-color-4);
            }

            .purpleText {
                color: var(--decor-color-6);
                    @include mobile{
                    margin-right: 0;
                }
            }
        }

        .prideFlag {
            max-width: 270px;
            height: auto;

            @include min-hd{
                max-width: 250px;
            }

            @include ipad{
                max-width: 180px;
            }
        }

        .calendarInfo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px;
            align-items: stretch;
            background-color: var(--decor-color-7);
            color: #000;
            max-width: 215px;
            border-radius: 10px;

            span {
                text-align: center;
                display: inline-block;
                margin-bottom: 1em;
            }

            .button {
                background-color: var(--complementary-color);
                font-size: 1em;
                border-color: var(--complementary-color);
                padding: 10px 20px;

                &:hover {
                    background-color: var(--decor-color-7);
                    color: var(--complementary-color);
                }
            }
        }
    }
}