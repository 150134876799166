@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';


.collapsed {
    @include ipad {
        display: none;
    }
}

.filterClose {
    display: none;
    z-index: 2;

    @include ipad {
        position: fixed;
        display: none;
        transform: translateX(-50%);
        bottom: 5px;
        left: 50%;
        z-index: 5;

        button {
            background-color: var(--primary-color);
            border: unset;

            &:hover {
                background-color: var(--secondary-color);
            }
        }
    }
}

.filterClose.active {
    @include ipad {
        display: block;
    }
}

.filterOpen {
    display: none;
    z-index: 2;

    @include ipad {
        display: none;
        left: 50%;


        button {
            background-color: var(--primary-color);
            border: unset;

            &:hover {
                background-color: var(--secondary-color);
            }
        }
    }
}

.filterOpen.active {
    @include ipad {
        display: flex;
        justify-content: center;
    }
}

.sidebarWrapper {
    height: 100%;
    top: 22vh;
    width: 30%;

    @include ipad {
        width: 100%;
        position: unset;
    }

    .filterWrapper {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        background-color: #ffff;

        .title {
            h2 {
                border-radius: 10px 10px 0px 0px;
                text-align: left;
                color: #ffff;
                background-color: var(--primary-color);
                font-weight: 600;
                margin: 0px;
                padding: 15px 0 15px 20px;
            }
        }

        .contents {

            padding: 20px;

            h3 {
                font-weight: 600;
                margin: 5px 0;
            }

            .buttonWrapper {
                .searchGroup {
                    border-bottom: 1px solid #b9b9b9;
                    padding: 20px 0px;

                    @include ipad {
                        padding: 0px;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    p {
                        margin-bottom: 5px;
                    }

                    h4 {
                        font-size: 1.3em;
                        margin-bottom: 0px;
                        font-weight: 600;
                    }

                    .filterBtns {

                        .buttonGroup {
                            margin-top: 20px;

                            button {
                                background: none;
                                border: 0;
                                width: 100%;
                                padding: 8px;
                                border-radius: 35px;
                                background-color: var(--primary-color);
                                border: 2px solid var(--primary-color);
                                color: #fff;
                            }

                            .clearButton {
                                margin-bottom: 15px;

                            }
                        }

                        @include ipad {
                            display: flex;
                            justify-content: space-around;

                            button {
                                margin-left: 5px;
                            }
                        }

                        @include mobile {
                            display: block;
                        }

                        .selectBox {
                            margin-top: 10px;

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }
                    }
                }

                .searchGroupChild {
                    padding-top: 0px;
                }

                .contractType {
                    margin-top: 15px;
                    justify-content: left;
                    font-weight: 400;

                    .filterCheckbox {
                        padding: 7px 5px;

                        input {
                            margin-right: 10px;
                            scale: 1.2;
                        }

                        label {
                            font-size: 1em;
                        }

                        @include exsmall {
                            font-size: 0.9em;
                        }
                    }
                }

                .selectBox {
                    button {
                        background-color: #ffffff;
                        border-radius: 5px;
                        border: 1px solid var(--primary-color);
                        padding: 10px 28px;
                        // margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        @include exsmall {
                            font-size: 12px;
                            padding: 9px 9px;
                        }

                        @include small {
                            padding: 10px 10px;
                        }
                    }
                }

                @include ipad {
                    display: block;
                    justify-content: center;
                }
            }

            @include ipad {
                position: unset;
                width: 100%;
                z-index: 999;
                border-radius: 20px;
                max-height: unset;
                overflow-y: auto;
            }
        }
    }
}

.values {
    margin: 0px 0px 10px 0px;

    .Message {
        .contratcMessageWrapper {
            .contractMessage {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0 0 10px 0;

                .closeButton {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 8px 8px 0;
                    background-color: var(--primary-transparent);
                    border-radius: 5px;
                    padding: 2px 7px;
                    width: 47%;
                    font-size: 15px;

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #5d5d5d;
                    }

                    button {
                        display: flex;
                        font-weight: 600;
                        justify-content: center;
                        align-items: center;
                        border: unset;
                        background: transparent;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}

h3 {
    font-weight: 600;

    span {
        color: var(--primary-color);
        margin-left: 10%;
        cursor: pointer;
    }
}

.accordionItem {
    border: unset;

    .accordionHeader {
        margin-top: 0;
    }
}