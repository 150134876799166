@import '../../assets/styles/variables';

._404 {
    flex-grow: 1;
    display: flex;
    align-items: center;
    min-height: 600px;

    h1 {
        font-size: 3em;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }

    h2 {
        font-size: 2em;
        color: #fff;
        margin: 0;
    }

    p {
        margin-bottom: 0;
        color: #fff;
    }

    .btn {
        background-color: #fff;
        color: var(--primary-color);
        margin-top: 1em;
        font-weight: bold;
        border: none;

        &:hover {
            color: #fff;
            background-color: var(--primary-color);
        }
    }
}