@import '../../../assets//styles/mixins';
@import '../../../assets/styles/variables';

.popupWrap {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
    top: 0;

    .popupBackdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #00000052;
    }

    .popup {
        @include max-wid($max: 995px);
        margin: 0 auto;
        display: flex;
        padding: 50px;
        background-color: white;
        border-radius: 10px;
        position: absolute;
        overflow: hidden;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include ipad() {
            display: block;
            top: 50%;
            padding: 25px 25px 25px 25px;
            width: 500px;
        }

        @include mobile() {
            padding: 25px 20px;
            width: 400px;
        }

        @include exsmall() {
            width: 312px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 100%;
            background-color: var(--popup-background);
            top: 0;
            left: 75%;
            transform: rotate(14deg);
            scale: 1.5;
            z-index: 0;

            @include ipad() {
                top: 73%;
                left: 24%;
                transform: rotate(90deg);
            }

            @include mobile() {
                top: 64%;
            }

            @include exsmall() {
                top: 56%;
            }
        }

        .crossIcon {
            font-size: 30px;
            right: 19px;
            position: absolute;
            z-index: 100;
            top: 22px;
            cursor: pointer;

            @include ipad() {
                font-size: 24px;
                right: 14px;
            }
        }

        .jobLogos {
            @include max-wid($max: 60%);
            position: relative;
            z-index: 1;

            @include ipad() {
                @include max-wid($max: 100%);
            }

            .logo1 {
                @include max-wid($max: 393px);

                @include ipad() {
                    @include max-wid($max: 188px);
                }
            }

            .gradient {
                @include max-wid($max: 315px);
                margin-top: 10px;
                height: 3px;

                @include ipad() {
                    @include max-wid($max: 190px);
                }
            }

            p {
                font-size: 14px;
                margin: 30px 0;

                @include ipad() {
                    margin: 19px 0 30px 0;
                }

                @include mobile() {
                    margin: 15px 0 15px 0;
                }

                @include exsmall() {
                    font-size: 12px;
                }
            }

            .logosWrap {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;

                @include mobile() {
                    margin: 0 0px;
                }

                @include max-wid($max: 87%);

                @include ipad() {
                    @include max-wid($max: 100%);
                }

                li {
                    flex-basis: 50%;
                    max-width: 50%;
                    padding: 0 5px 10px;
                    height: 70px;

                    @include mobile() {
                        height: 62px;
                        padding: 0 3px 10px;
                    }

                    @include exsmall() {
                        height: 50px;
                    }

                    a {
                        display: block;
                        padding: 20px;
                        border: 1px solid #ccc;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include borders($b: 10px);

                        @include exsmall() {
                            padding: 10px;
                        }

                        img {
                            // @include max-wid($max:240px);
                            height: auto;
                            max-height: 50px;
                        }
                    }
                }
            }
        }

        .jobContents {
            position: relative;
            margin-left: 90px;
            z-index: 1;

            @include ipad() {
                margin-left: 0px;
                display: none;
            }

            p {
                padding: 100px 0 40px 0;
                margin-bottom: 0px;

                @include ipad() {
                    padding: 67px 0 20px 0;
                }
            }

            .line {
                @include max-wid($max: 246px);
                height: 1px;
                margin: 20px 0;
            }

            .commonText {
                font-size: 18px;

                @include ipad() {
                    font-size: 14px;
                    flex-direction: row;
                    display: flex;
                    align-items: center;
                }

                h5 {
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 0px;

                    @include ipad() {
                        font-size: 14px;
                        padding-right: 10px;
                    }
                }
            }

            .emilSection {
                .commonText {
                    @include ipad() {
                        font-size: 14px;
                        flex-direction: column;
                        display: flex;
                        align-items: start;
                        justify-content: start;
                    }
                }
            }
        }

        .jobContentsNew {
            display: none;

            @include ipad() {
                display: block;
                position: relative;
                z-index: 1;

                p {
                    margin-bottom: 0px;
                    padding: 20px 0 0 0;
                    font-size: 14px;

                    @include mobile() {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}