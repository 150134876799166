@import "../../assets/styles/mixins";

.lifeBtm {
    width: 68%;
    margin-left: auto;

    .noJobs {
        text-align: center;
        font-size: 30px;
    }

    .loadMoreBtnItem {
        display: flex;
        justify-content: center;

        .loadMoreBtn {
            margin-top: 20px;
        }
    }

    @include ipad {
        width: 100%;
        margin-top: 20px;
    }
}