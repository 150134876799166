@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.policyPage {
    .banner {
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include small {
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
        }

        .cntWrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: relative;

            .cnt {
                padding: (10*$ref-size) 0 (10 * $ref-size);
                z-index: 1;

                @include min-hd {
                    padding: (8*$ref-size) 0 (8 * $ref-size);
                }

                @include ipad {
                    padding: (5*$ref-size) 0 (5 * $ref-size);
                }

                @include mobile {
                    padding: (3*$ref-size) 0 (9 * $ref-size);
                }


                span {
                    display: block;

                    &.title {
                        font-size: 3em;
                        color: #fff;
                    }

                    &.subtitle {
                        font-size: 2em;
                        color: #fff;
                    }

                    @include min-hd {
                        &.title {
                            font-size: 2.5em;
                        }

                        &.subtitle {
                            font-size: 2em;
                        }
                    }

                    @include ipad {
                        &.title {
                            font-size: 2em;
                        }

                        &.subtitle {
                            font-size: 1.6em;
                        }
                    }
                }
            }

            img {
                max-height: 600px;

                @include min-hd {
                    max-height: 500px;
                }

                @include ipadpro {
                    max-height: 440px;
                }

                @include ipad {
                    max-height: 350px;
                }

                @include ipad {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

    }

    .privacyPolicyWrapper {
        color: #797979;
        padding: 40px 0px 60px 0px;
    }
}