.labelBox {
    display: flex;
    justify-content: flex-start;

    .checkbox {
        width: 13px !important;
        margin-right: 10px;
    }
}

.locationLabelBox {
    display: grid;
}

.removeLocationButton {
    margin-top: 9px;
    border: 1px solid var(--primary-color);
    background: none;
    padding: 2px 28px;
    color: var(--primary-color);
    border-radius: 25px;
}

.addLocationButton {
    margin-bottom: 13px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    padding: 10px 32px;
    color: #fff;
    border-radius: 25px;

    &:hover {
        background: none;
        color: var(--primary-color);
    }
}

.wfh {
    display: flex;

    .checkboxWrapper {
        input {
            margin-right: 10px;
            width: 17px;
            height: 17px;
        }
    }
}