@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.accordion {
    text-align: left !important;
    padding: 0;
    position: relative;
    border-bottom: 1px solid rgb(214, 214, 214);
}

.accordionHeader {
    padding-right: 25px !important;
    cursor: pointer;
    position: relative;
    padding: 10px;
}

.accordionHeader::after {
    content: '+';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 27%;
    right: 10px;
    font-size: 25px;
    transform: translateY(-50%);
}

.accordionHeader.active::after {
    content: '-';
}

.accordionContent {
    padding: 10px;

    .links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        button,
        a {
            border: none;
            background: none;
            padding: 0;
            transition: 0.3s ease;
            padding: 3px 40px;
            border: 1px solid #007bff; // Replace with your color
            border-radius: 35px;
            color: #007bff; // Replace with your color
            display: block;

            @media (max-width: 768px) {
                padding: 3px 25px;
            }

            @media (max-width: 576px) {
                width: 125px;
                margin-top: 10px;
            }

            @media (max-width: 400px) {
                margin: 0 auto;
                margin-top: 5px;
            }
        }

        .delete {
            background: #FFE5E5;
            color: #E86060;
            border-color: #FFE5E5;
        }
    }

    table,
    tr {
        width: 100%;
        border-collapse: collapse;
    }

    td {
        padding: 7px 0;
        width: auto;

        &:first-child {
            width: 45%;
        }
    }
}