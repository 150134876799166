@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.dashboard {
    flex-grow: 1;
    background: #eaecf0;
    padding: 30px 30px 30px 20px;
    position: relative;
    z-index: 2;
    @include mobile {
        padding: 15px 0;
    }

    a {
        @include mobile {
            width: 200px;
            text-align: center;
        }
    }

    .dashboardWrap {
        border-radius: 10px;
        filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.16));
        background: #fff;
    }

    .head {
        padding: 40px 60px;
        border-radius: 8px 8px 0 0;
        color: #fff;
        background-color: #021637;

        @include mobile {
            padding: 30px;
        }

        @include exsmall {
            padding: 15px 30px;
        }

        .dashboardSelect {
            margin: 10px 0;
            width: 300px;
            color: #021637;

            @include small {
                width: 100%;
            }
        }

        h1 {
            margin: 0;
            font-size: 30px;

            @include exsmall {
                font-size: 25px;
            }
        }

        a {
            @include exsmall {
                width: unset;
                padding: 8px 15px;
            }
        }

        .titleAndButton {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .jobPostBtn {
                display: inline-block;
                padding: 15px 54px 15px 35px;
                border-radius: 45px;
                border: 1px solid #fff;
                margin-right: 0.5em;
                background-color: #fff;
                font-size: 20px;
                color: #021637;
                transition: 0.3s ease;

                @include mobile {
                    padding: 12px 20px;
                    font-size: 15px;
                }

                @include exsmall {
                    padding: 8px 10px;
                    display: block;
                    margin: 0 0 0 auto;
                }

                &:hover {
                    color: var(--complementary-color);
                    background-color: #fff;
                }
            }
        }
    }

    .subHead {
        display: flex;
        justify-content: space-between;
        padding: 50px 60px 40px 60px;
        align-items: center;

        @include mobile {
            padding: 30px;
        }

        img {
            width: 150px;

            @include mobile {
                width: 130px;
            }

            @include exsmall {
                margin: 0 auto;
                display: block;
            }
        }
    }

    .links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        @include mobile {
            display: block;
        }

        li {
            @include exsmall {
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .link {
            padding: 15px 35px;
            border-radius: 40px;
            border: 1px solid #021637;
            border-color: #021637;
            margin-right: 0.5em;
            color: #021637;
            transition: 0.3s ease;
            display: inline-block;
            margin-bottom: 0.5em;

            @include mobile {
                padding: 15px;
            }

            @include exsmall {
                padding: 10px 15px;
            }


            &:hover {
                color: #fff;
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }

            &.active {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                color: #fff;
            }
        }
    }

    .downloadBtn {

        @include mobile {
            display: none !important;
        }

        button {
            border-radius: 40px;
            border: 1px solid #021637;
            background: #0bbde4;
            border-color: #0bbde4 !important;
            padding: 15px 35px;
            transition: 0.3s ease;

            &:hover {
                background: #0992b1;
            }
        }
    }

    .downloadDataWrapper {
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 10px;
        background: #f5f5f5;
        text-align: center;
        display: none;
        padding: 20px;

        @include mobile {
            display: block;
        }

        span {
            font-weight: bold;
            font-size: 18px;
        }

        a {
            text-align: center;
            padding: 15px 26px;
            background: #0bbde4;
            border-radius: 35px;
            display: inline-block;
            width: 180px;
            margin-left: 10px;

            @include small {
                display: block;
                width: 180px;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }

    .tableWrap {
        padding: 0 15px 50px 15px;

        // @include laptop {
        //     overflow-x: scroll;
        // }

        @include mobile {
            padding: 0 30px 30px 30px;
            overflow-x: unset;
        }
    }

}