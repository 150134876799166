@import '../../../../assets/styles/variables';

.toggleOuter{
    .toggleWrap{
        display: block;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #b5b5b5;
        width: max-content;
        margin-bottom: 1em;
        .toggle{
            position: relative;
            display: flex;
            transition: all 0.3s ease-in-out;
            input{
                display: none;
                &:checked {
                    & + label{
                        background-color: #b6c9f9;
                    }
                }
                
            }
            label{
                padding: 7px 12px;
                cursor: pointer;
                user-select: none;
                transition: 0.3s ease-in-out;
                background-color: #fff;
                &:first-child{
                    border-right: 1px solid #b5b5b5;
                }
            }
        }
    }
}
.inputWrap{
    position: relative;
    .testUrl{
        top: 100%;
        position: absolute;
        display: block;
        color: var(--primary-color);
        font-size: 0.9em;
        margin-top: 0.3em;
    }
}