.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    transition: 0.3s ease-in-out;
    opacity: 1;

    &.hide {
        opacity: 0;
        z-index: -999;
    }

}