@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.jobAlert {
    .formWrapper {
        .container {
            .form {
                .AlertMessage {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;

                    h3 {
                        margin: 10px 0;
                    }
                }

                .red {
                    color: red;
                    background-color: rgb(255, 198, 198);
                    border: 1px solid red;
                }

                .green {
                    color: green;
                    background-color: rgb(198, 255, 198);
                    border: 1px solid green;
                }

                .jobWorkFromHomeFieldsWrap {
                    padding: 36px;
                    border-bottom: 1px solid #aaa;

                    h4 {
                        margin-bottom: 0;
                    }

                    input {
                        width: 16px;
                        height: 16px;
                        border-radius: 5px;
                    }
                }

                .submitBtn {
                    display: flex;
                    justify-content: space-between;

                    .buttonWrapper {
                        @include mobile {
                            margin-top: 20px;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}