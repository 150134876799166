@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.inputBox {
    .inputField {
        background-color: #fff;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        display: inline-flex;
        justify-content: space-between;
        padding: 10px 28px;
        width: 100%;
    }
}