@import "../../assets/styles/variables";

.decorWrapper{
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 4px;
    border-radius: 20px;
    overflow: hidden;;
    div{
        flex-grow: 1;
        &.red{
            background-color: var(--decor-color-1)
        }
        &.orange{
            background-color: var(--decor-color-2);
        }
        &.yellow{
            background-color: var(--decor-color-3);
        }
        &.green{
            background-color: var(--decor-color-4);
        }
        &.blue{
            background-color: var(--decor-color-5);
        }
        &.purple{
            background-color: var(--decor-color-6);
        }
        &.violet{
            background-color: var(--decor-color-7);   
        }
    }
}