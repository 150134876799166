@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.teaserWrapper {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    padding: (5.6*$ref-size) 0 (5.6 * $ref-size);

    .sideStrips {
        position: absolute;
        top: 0;
        right: 0%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .teaser {
        * {
            text-align: center;
        }

        h2 {
            margin: 0px 0px 20px 0px;
            color: var(--secondary-color);
            font-size: 41px;
            font-weight: bold;

            @include mobile {
                font-size: 30px;
                ;
            }
        }

        .teaserCnt {
            align-items: center;
            justify-content: center;

            p {
                color: #212124;
                margin-right: 5rem;
                margin-bottom: 30px;
                font-size: 20px;
                width: 100%;

                @include ipadpro {
                    margin-bottom: 1em;
                }

                @include ipad {
                    margin-right: 0;
                }
            }

            a {
                padding: 10px 25px;
                font-size: 20px;
                border-radius: 30px;
            }

            @include ipad {
                flex-direction: column;
            }
        }
    }

    .artifactLeft {
        position: absolute;
        width: 560px;
        transform: translateY(-50%);
        left: -330px;
        top: 25%;
        z-index: -1;

        @include ipad {
            left: -330px;
            top: 25%;
        }

        @include exsmall {
            left: -275px;
            top: 40%;
        }

        @include xxsmall {
            left: -186px;
            top: 26%;
        }
    }

    .artifactRight {
        position: absolute;
        width: 560px;
        top: 25%;
        transform: translateY(-50%);
        right: -180px;
        z-index: -1;

        @include ipad {
            top: 40%;
            right: -170px;
            z-index: -1;
        }

        @include exsmall {
            top: 65%;
            right: -170px;
            z-index: -1;
        }

        @include xxsmall {
            top: 75%;
            right: -102px;
        }
    }

    @include mobile {
        padding: (3.6*$ref-size) 0 (3.6 * $ref-size);
    }
}