
@import '../../assets/styles/mixins';

.title{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 60%;
    margin: auto auto 40px;
    @include min-hd{
        max-width: 100%;
    }
    h2{
        width: -webkit-fill-available;
        text-align: center;
        margin-bottom: 0;
        @include mobile{
            width: 750px;
        }
    }
}