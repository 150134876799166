@import '../../../assets/styles/variables';

.banner {
    .cnt {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .bannerBtn {
            border-radius: 10px;
            background-color: #fff;
            color: #000;
            border: 2px solid #fff;

            &:hover {
                background-color: var(--primary-color);
                color: #fff;
                border: 2px solid #fff;

                .icon {
                    color: #fff;
                }
            }

            .icon {
                color: var(--primary-color);
                margin-right: 10px;
                width: 20px;
                height: 20px;
                transition: 0.3s ease-in-out;
            }
        }

        .req {
            position: absolute;
            bottom: -90px;
            right: 0;
        }
    }
}