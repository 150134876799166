@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.jobSinglePage {
    .banner {
        background-clip: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        padding: 9.5*$ref-size 0 7.5*$ref-size;

        @media (max-width: 1920px) {
            background-size: unset;
        }

        @include ipad {
            padding: 7.5*$ref-size 0;
        }

        .headline {
            color: #fff;
            font-weight: bold;
            text-align: center;
            margin-bottom: 1.5em;
        }

        .expiredTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: #000000;
            padding: 10px 30px 10px 30px;
            border-radius: 30px;
            font-weight: bolder;
        }

        .jobBlocks {
            display: flex;
            gap: 20px;
            margin-bottom: 2.5em;
            justify-content: center;

            svg {
                height: 56px;
                width: auto;
                margin-right: 18px;

                @include ipad {
                    height: 45px;
                }

                @include mobile {
                    height: 40px;
                }

                @include small {
                    height: 37px;
                }
            }

            @include ipad {
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .bannerBtn {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            background: none;
            border: none;
            color: #fff;
            border: 1px solid #fff;
            padding: 20px 70px;
            border-radius: 5px;
            font-size: 1.2em;

            &:hover {
                background-color: var(--primary-color);
                color: #fff;
                border: 1px solid #fff;
            }

            @include ipadpro {
                padding: 15px 60px;
            }


        }

        @include mobile {
            padding: 30px 0px 90px 0px;
        }
    }

    .jobDetails {
        border-top-right-radius: 80px;
        border-top-left-radius: 80px;
        background-color: #fff;

        position: relative;
        overflow: hidden;

        @include mobile {
            border-top-right-radius: 40px;
            border-top-left-radius: 40px;
        }

        @include small {
            padding: 1*$ref-size 0;
        }

        .sideStrips {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(60%, -50%);

            @include min-hd {
                display: none;
            }
        }

        .ovwSec {
            margin-top: 2em;
            padding: 4*$ref-size 0;

            @include ipad {
                padding: 50px 0;
            }

            @include mobile {

                padding: 26px 0;
            }

            .headline {
                font-size: 2em;
                font-weight: bold;
            }

            .warpContents {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                padding-bottom: 30px;

                @include ipad() {
                    display: block;
                    padding-bottom: 0px;
                }

                .btnBlock {
                    position: relative;
                    display: flex;
                    align-items: stretch;
                    flex-direction: column;
                    max-width: 55%;
                    flex-basis: 55%;

                    @include ipad {
                        flex-direction: row;
                        max-width: 100%;
                        flex-basis: 100%;
                        margin-top: 1.5em;
                        align-items: center;
                        padding-bottom: 20px;
                    }

                    @include small {
                        flex-direction: column;
                        max-width: 100%;
                        flex-basis: 100%;
                        margin-top: 1.5em;
                        align-items: center;
                    }

                    .stickyBtnSection {
                        position: absolute;
                        top: 0;
                        padding: 2.2em 3.5em 3em 3.5em;
                        background-color: var(--primary-color);
                        border-radius: 10px;
                        text-align: center;
                        width: 430px;

                        @include ipad {
                            position: relative;
                            width: 100%;
                            padding: 2.2em 7em 3em 7em;
                        }

                        @include small {
                            padding: 2.2em 5em 3em 5em;
                        }

                        h4 {
                            font-size: 24px;
                            color: #fff;
                            font-weight: bold;
                            margin-bottom: 40px;
                        }

                        .sideBarStripsLeft {
                            position: absolute;
                            left: 0;
                        }

                        .sideBarStripsRight {
                            position: absolute;
                            right: 0;
                            bottom: 110px;
                        }
                    }


                    &.fixed .stickyBtnSection {
                        position: fixed;
                        top: 122px;
                    }

                    figure {
                        flex-grow: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        padding: 12px 4em;
                        height: 140px;
                        border-radius: 5px;
                        margin-bottom: 1.5em;

                        @include ipad {
                            padding: 0;
                            flex-grow: 0;
                            margin-right: 0.5em;
                        }


                        @include mobile {
                            width: 100%;
                            border: 1px solid #c9c9c9;
                            border-radius: 20px;
                            padding: 40px;
                            height: 110px;
                        }

                        @include small {
                            padding: 50px;
                            height: 65px;
                        }

                        img {
                            max-height: 135px;
                            width: auto;
                            object-fit: contain;

                            @include ipadpro {
                                max-height: 100px;
                            }

                            @include ipad {
                                width: 100%;
                            }

                            @include small {
                                max-height: 75px;
                                object-fit: contain;
                            }
                        }
                    }

                    .jobBtn {
                        color: #fff;
                        border: 1px solid #fff;
                        padding: 0.6em;
                        border-radius: 10px;
                        font-size: 1.3em;
                        font-weight: bold;
                        cursor: pointer;
                        width: 100%;

                        @include ipad {
                            margin-right: 0.5em;
                        }

                        &.jobProfileBtn {
                            margin-top: 1.5em;

                            @include ipad {

                                margin-right: 0;
                            }
                        }

                        &:hover {
                            background-color: #fff;
                            color: var(--primary-color);
                        }
                    }

                    .jobProfileBtn {
                        background: #fff;
                        border-color: #fff;
                        color: var(--primary-color);

                        &:hover {
                            background-color: var(--primary-color);
                            color: #fff;
                            border-color: #fff;
                        }

                        @include mobile {
                            padding: 15px 40px;
                        }

                        @include small {
                            padding: 15px 25px;
                        }

                        @include exsmall {
                            padding: 15px 15px;
                        }
                    }

                    @include mobile {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }

                .contentBlock {
                    margin-right: 80px;
                    max-width: 100%;
                    flex-basis: 100%;

                    .attachmentListing {
                        .listingContainer {

                            li {
                                display: flex;
                                align-items: center;
                                margin-top: 10px;
                                cursor: pointer;

                                .fileIcon {
                                    font-size: 30px;
                                    margin-right: 5px;
                                    color: var(--primary-color);
                                }
                            }

                            h4 {
                                margin-bottom: unset;
                            }
                        }
                    }

                    @include ipad {
                        margin-right: 0;
                    }

                    .highlightContainer {
                        background-color: #ffff;
                        padding: 20px;
                        border-radius: 10px;
                        width: 100%;
                        border: 1px solid #D2D2D2;
                        margin-bottom: 4.5em;
                        padding: 40px 104px 40px 50px;

                        @include laptop {
                            padding: 30px 85px 30px 50px;
                        }

                        @include mobile {
                            padding: 30px 50px 30px 35px;
                        }

                        @include small {
                            padding: 20px;
                        }

                        .header {
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            .logo {
                                margin-right: 10px;
                                width: 150px;
                                height: 150px;

                                @include ipadpro {
                                    width: 100px;
                                    height: 100px;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .title {
                                h3 {
                                    font-size: 26px;
                                    text-align: left;
                                    color: var(--primary-color);
                                    margin: 0;
                                    margin-bottom: 25px;
                                    width: 70%;

                                    @include laptop {
                                        margin-bottom: 25px;
                                    }

                                    @include ipadpro {
                                        font-size: 23px;
                                        margin-bottom: 20px;
                                        width: 100%;
                                    }

                                    @include ipad {
                                        font-size: 26px;
                                        width: 72%;
                                    }

                                    @include mobile {
                                        font-size: 23px;
                                        margin-bottom: 15px;
                                        width: 100%;
                                        margin-bottom: 15px;
                                    }

                                    @include small {
                                        font-size: 21px;
                                    }

                                    @include exsmall {
                                        font-size: 19px;
                                    }
                                }

                                .aiDecorLine {
                                    width: 50%;
                                    text-align: left;
                                }
                            }
                        }

                        .content {
                            position: relative;
                            position: relative;
                            max-width: 600px;

                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                position: relative;

                                li {
                                    margin: 0;
                                    padding-left: 40px;
                                    padding-bottom: 25px;
                                    position: relative;
                                    display: flex;
                                    align-items: center;

                                    &:last-child {
                                        &::after {
                                            content: none;
                                        }
                                    }

                                    @include mobile {
                                        padding-bottom: 20px;
                                    }

                                    @include exsmall {
                                        padding-bottom: 15px;
                                    }

                                    &::before {
                                        content: '';
                                        background: #fff;
                                        border: 2px solid var(--primary-color);
                                        display: inline-block;
                                        position: absolute;
                                        border-radius: 50%;
                                        left: 14px;
                                        width: 15px;
                                        height: 15px;
                                        z-index: 2;
                                        top: 6px;
                                    }

                                    &::after {
                                        content: '';
                                        background: var(--primary-color);
                                        display: inline-block;
                                        align-items: center;
                                        position: absolute;
                                        left: 20px;
                                        top: 7px;
                                        height: 100%;
                                        width: 2px;
                                        z-index: 1;
                                    }

                                    p {
                                        margin: 0;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        font-weight: 600;
                                        font-size: 18px;

                                        @include mobile {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .titleSec {
                    border: 1px solid #dcdcdc;
                    border-radius: 10px;
                    padding: 70px;
                    width: 100%;
                    margin-bottom: 4.5em;
                    // display: flex;
                    // align-items: center;
                    // justify-content: flex-start;
                    // flex-wrap: wrap;

                    @include ipad {
                        padding: 50px;
                    }

                    @include small {
                        padding: 35px;
                    }

                    .jobTitle {
                        .icon {
                            color: var(--primary-color);
                            // min-width: 50px;
                            // font-size: 1.3em;
                            // min-height: 30px;
                            font-size: 25px;

                            @include mobile() {
                                font-size: 18px;
                            }
                        }
                    }

                    .location {
                        .icon {
                            color: var(--primary-color);
                            // min-width: 20px;
                            // font-size: 3em;

                            // @include mobile {
                            //     font-size: 1.3 em;
                            // }
                            font-size: 25px;

                            @include mobile() {
                                font-size: 18px;
                            }
                        }
                    }

                    .contract_icon,
                    .salary {
                        .icon {
                            color: var(--primary-color);
                            // min-width: 50px;
                            // font-size: 1.3em;
                            // min-height: 30px;
                            font-size: 25px;

                            @include mobile() {
                                font-size: 18px;
                            }
                        }
                    }

                    .location,
                    .contract_icon {
                        border-bottom: 1px solid #dcdcdc;
                    }

                    .location,
                    .contract_icon,
                    .salary {
                        padding: 1.7em 0;
                    }

                    span {
                        display: flex;
                        margin-bottom: 5px;

                    }

                    // .location svg {
                    //     transform: scale(0.7);
                    // }

                    @include mobile {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &>div:last-child {
                        padding-bottom: 0;
                    }

                    &>div {

                        align-items: center;
                        margin-right: 1.5em;

                        @include mobile {
                            margin-right: 0;
                            margin-bottom: 1em;
                        }

                        h3 {
                            font-size: 1.7em;
                            font-weight: bold;
                            margin-left: 8px;
                            margin-top: 0;
                            margin-bottom: 1em;
                            color: var(--primary-color);

                            @include ipad {
                                font-size: 1.5em;
                            }

                            @include mobile {
                                font-size: 1.3em;
                            }

                        }

                        img {
                            width: 36px;
                            height: 36px;
                            object-fit: contain;
                        }
                    }
                }

                .descriptionSec p {
                    margin-bottom: .8em;
                }
            }
        }

        .jobFooter {
            margin-top: 3.5em;
            align-items: center;

            @include ipad {
                flex-direction: column;
                align-items: flex-start;
            }

            p {
                margin-bottom: 0;

                a {
                    color: var(--primary-color);
                    transition: 0.3 ease;

                    &:hover {
                        color: #000000;
                    }
                }

                @include ipad {
                    margin: 1.5em 0;
                }
            }

            .footerBtn {
                background-color: var(--primary-color);
                color: #fff;
                border-color: var(--primary-color);
                padding: 0.7em 1em;
                border-radius: 10px;
                font-size: 1.2em;
                font-weight: bold;
                cursor: pointer;
                padding: 25px 90px;
                margin-bottom: 30px;

                @include ipadpro {
                    padding: 15px 60px;
                    margin-bottom: 20px;
                }

                @include mobile {
                    padding: 13px 40px;
                }

                &:hover {
                    background-color: #fff;
                    color: var(--primary-color);
                }
            }
        }
    }
}

.expired {
    position: relative;
}

.expired::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
}

.expired * {
    pointer-events: none;
}