@import '../../assets/styles/variables';

.banner {
    color: #fff;
    flex-grow: 1;
    padding: 6*$ref_size 0;

    .cnt {
        position: relative;
    }
}