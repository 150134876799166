@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.accordion {
    text-align: left !important;
    padding: 0;
}

.accordionHeader {
    padding-right: 25px !important;
}

.accordionHeader::after {
    content: '+';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 25px;
    right: 10px;
    font-size: 25px;
}

.accordionHeader.active::after {
    content: '-';
}

.accordionContent {

    .mobViews {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .links {

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        button,
        a {
            border: none;
            background: none;
            padding: 0;
            transition: 0.3s ease;
            padding: 3px 40px;
            border: 1px solid $lgbt-color-blue;
            border-radius: 35px;
            color: $lgbt-color-blue;
            display: block;

            @include ipad {
                padding: 3px 25px;
            }

            @include mobile {
                width: 125px;
                margin-top: 10px;

            }

            @include small {
                margin: 0 auto;
                margin-top: 5px;
            }

        }

        .delete {
            background: #FFE5E5;
            color: #E86060;
            border-color: #FFE5E5;
        }

    }


    table,
    tr {
        width: 100%;
    }

    td {
        padding: 7px 0;
        width: auto;

        .active {
            background-color: #EDF8F3;
            color: #3E9267;
        }

        .inactive {
            background-color: #F1F1F1;
            color: #B3BCC6;
        }

        .draft {
            background-color: #ECF2FF;
            color: #ECF2FF;
        }

        .expired {
            background-color: #B3BCC6;
            color: #F1F1F1;
        }
    }

    td:first-child {
        width: 45%;
    }

    .Lgbtjobs {
        color: #365bb8;
    }

    .Bmejobs {
        color: #b94d7f;
    }

    .Disabilityjob {
        color: #00a0e9;
    }

    .Neurodiversityjobs {
        color: #21b193;
    }
}