@import '../../../assets/styles/mixins';

.header {
    width: 100%;
    background-color: #fff;
    z-index: 999;
    transition: transform 0.5s ease-in-out;
    position: fixed;
    top: 0;

    &.scrolled {
        box-shadow: 0 -6px 14px 5px rgba(0, 0, 0, 0.6);
    }

    &.unScrolled {
        position: static;
        animation-name: unfixed_header;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
    }

    .navWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include ipadpro {
        padding: 1.7em 0;
    }

    @include small {
        padding: 1.2em 0;
    }

    @include mobileLand {
        padding: 0.7em 0;
    }
}

@keyframes fixed_header {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes unfixed_header {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}