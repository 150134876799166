@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.partnersSecWrapper {
    padding: 3*$ref-size 0;
    background-color: #F9F9F9;

    @include mobile {
        padding: 2*$ref-size 0;
    }

    .partnersSec {
        max-width: 1320px;

        @include laptop {
            max-width: 1130px;
        }

        @include ipadpro {
            max-width: 100%;
        }

        .partnerSlider {
            .slideItem {
                height: 100%;
                margin: 0 15px;
                display: flex !important;
                flex-direction: column;
                align-items: center;

                @include ipad {
                    margin: 0 10px;
                }

                img {
                    margin: auto;
                    object-fit: contain;
                    max-height: 80px;

                }

                .sliderBottomRow {
                    display: block;
                }

                .top {
                    width: 240px;
                    height: 100px;
                    padding: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #d9d9d9;
                    border-radius: 20px;
                    background-color: #ffff;

                    @include laptop {
                        width: 210px;
                    }

                    @include ipadpro {
                        width: 185px
                    }

                    @include ipad {
                        width: 130px;
                    }

                    @include mobile {
                        width: 154px;
                    }

                    @include small {
                        width: 190px;
                    }

                    @include exsmall {
                        width: 135px;
                    }

                    @include xxsmall {
                        width: 105px;
                    }
                }

                .bottom {
                    width: 240px;
                    height: 100px;
                    padding: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #d9d9d9;
                    border-radius: 20px;
                    background-color: #ffff;

                    @include laptop {
                        width: 210px;
                    }

                    @include ipadpro {
                        width: 185px
                    }

                    @include ipad {
                        width: 130px;
                    }

                    @include mobile {
                        width: 154px;
                    }

                    @include small {
                        width: 190px;
                    }

                    @include exsmall {
                        width: 135px;
                    }

                    @include xxsmall {
                        width: 105px;
                    }
                }
            }
        }
    }
}