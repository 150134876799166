@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.employerBasicTemplate {
    flex-grow: 1;

    figure {
        width: 200px;
        height: 100px;

        img {
            max-height: 150px;
            max-width: 150px;
        }
    }

    .secJobListing {
        width: 100%;

        a {
            figure {
                @include mobile {
                    width: 170px;
                    height: 85px;
                }

                @include exsmall {
                    width: 135px;
                    height: 80px;
                }
            }
        }
    }
}

.companyBanner {
    background-color: var(--primary-color);
    position: relative;
    margin-bottom: 100px;

    @include hd {
        padding-top: 35px;
    }

    .bannerSub {
        display: flex;
        align-items: center;

        @include ipadpro {
            align-items: end;
        }

        .bannerLeft {
            @include ipadpro {
                padding-bottom: 40px;

                @include mobile {
                    padding-bottom: 0;
                }
            }

            .logo {
                width: 220px;
                height: 110px;
                padding: 10px;
                background-color: #ffff;
                border-radius: 19px;
                margin-bottom: 43px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include ipad {
                    width: 200px;
                    height: 100px;
                }

                @include xxsmall {
                    width: 150px;
                    height: 75px;
                }

                img {
                    width: 200px;
                    height: 105px;
                    object-fit: contain;

                    @include ipad {
                        width: 160px;
                        height: 95px;
                    }

                    @include xxsmall {
                        width: 120px;
                        height: 70px;
                    }
                }
            }

            h1 {
                color: #ffff !important;
                font-size: 55px;
                line-height: 1.2;
                font-weight: 500;

                @include laptop {
                    font-size: 45px;
                }

                @include ipadpro {
                    font-size: 40px;
                }

                @include mobile {
                    font-size: 35px;
                }

                br {
                    @include ipadpro {
                        display: none;
                    }
                }
            }
        }
    }
}