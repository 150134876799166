@import'../../../assets/styles/mixins';
@import'../../../assets/styles/variables';

.bannerpopupwrapper {
    padding: 40px;
    background-color: white;
    max-width: 560px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    box-shadow: 2px 2px 10px #0000001A;
    border: 1px solid #EBE9E9;
    opacity: 1;
    border-radius: 8px;

    @include mobile {
        padding: 20px;
        right:unset;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }

    .bannerpopupheading {
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 16px;
        color: black;

        @include mobile {
            font-size: 20px;
        }
    }

    .popupline {
        max-width: 502px;
        overflow: visible !important;
        &>div:first-child {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-1);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-1);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-1);
        }

        &>div:nth-child(2) {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-2);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-2);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-2);
        }

        &>div:nth-child(3) {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-3);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-3);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-3);
        }

        &>div:nth-child(4) {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-4);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-4);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-4);
        }

        &>div:nth-child(5) {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-5);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-5);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-5);
        }

        &>div:nth-child(6) {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-6);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-6);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-6);
        }

        &>div:last-child {
            -webkit-box-shadow: -1px 5px 12px 0px  var(--decor-color-7);
            -moz-box-shadow: -1px 5px 12px 0px  var(--decor-color-7);
            box-shadow: -1px 5px 12px 0px  var(--decor-color-7);
        }

        // .decorWrapper {
        // .red {
        // }

        // }
    }

    .bannerpopuptext {
        padding-top: 15px;

        p {
            font-size: 16px;
            font-weight: 400;
            color: black;

            @include ipad {
                br {
                    display: none;
                }
            }

            @include mobile {
                font-size: 12px;
            }

            a{
                color: #1953E4 !important;
                text-decoration: underline !important;
            }
        }
    }

    .wrapperBtn {
        display: flex;

        .btn {
            width: 155px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;

            @include mobile {
                font-size: 14px;
                width: 116px;
                height: 36px;
            }
        }

    }

    .wrapperBtn .btn:first-child {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
        color: white;
        margin-right: 10px;

        &:hover {
            background-color: white;
            color: var(--primary-color);
        }
    }

    .wrapperBtn .btn:last-child {
        background-color: white;
        color: var(--primary-color);
        border: 2px solid var(--primary-color);

        &:hover {
            background-color: var(--primary-color);
            color: white;
        }
    }

}