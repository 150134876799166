@import '../../assets/styles/mixins';

.companyCard{
    width: 100%;
    display: block;
    border-radius: 7px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease-in-out;
    &:hover{
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
        @include mobile{
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
        }
    }
    @include mobile{
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    figure{
        padding: 30px 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include small{
            padding: 20px 5px;
            min-height: 185px;
        }
        @include exsmall{
            min-height: 120px;
        }
        img{
            width: 100%;
            height: auto;
            max-width: 500px;
            aspect-ratio: 3/2;
            object-fit: contain;
        }
    }
    .jobCount{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
}