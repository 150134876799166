@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.loginConfirm {
    padding: 8*$ref_size 0 8*$ref_size;
    flex-grow: 1;
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: fixed;
    background-attachment: fixed;

    .cntOuter {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            text-align: center;
            margin-top: 0;
            margin-bottom: 0.5em;
            font-weight: bold;
            color: #fff;
        }

        h2 {
            margin: 0;
            color: #fff;
            font-weight: normal;
        }
    }
}