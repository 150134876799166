@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.searchPage {
    background-color: #f9f9f9;

    .banner {
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include small {
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
        }

        .cntWrap {
            position: relative;
            text-align: center;

            .cnt {
                padding: (5*$ref-size) 0 (5 * $ref-size);
                z-index: 1;

                @include min-hd {
                    padding: (8*$ref-size) 0 (8 * $ref-size);
                }

                @include ipad {
                    padding: (5*$ref-size) 0 (5 * $ref-size);
                }

                @include mobile {
                    padding: (3*$ref-size) 0 (9 * $ref-size);
                }


                span {
                    display: block;

                    &.title {
                        font-size: 3em;
                        color: #fff;
                    }

                    &.subtitle {
                        font-size: 2em;
                        color: #fff;
                    }

                    @include min-hd {
                        &.title {
                            font-size: 2.5em;
                        }

                        &.subtitle {
                            font-size: 2em;
                        }
                    }

                    @include ipad {
                        &.title {
                            font-size: 2em;
                        }

                        &.subtitle {
                            font-size: 1.6em;
                        }
                    }
                }
            }

            img {
                max-height: 600px;

                @include min-hd {
                    max-height: 500px;
                }

                @include ipadpro {
                    max-height: 440px;
                }

                @include ipad {
                    max-height: 350px;
                }

                @include ipad {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

    }

    .searchBox {
        margin-top: 0;
        transform: translateY(-50%);
        border-color: #2f2f2f;
        width: 100%;
        position: relative;
        z-index: 1;

        @include mobile {
            background-color: #2f2f2f;
        }
    }

    .gridOuter {
        padding-bottom: (7*$ref-size);
        position: unset;
        z-index: 1;
        overflow-x: clip;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 500px;
            background-color: #fff;
            z-index: -1;
            clip-path: ellipse(1300px 100% at 50% 100%);

            @include laptop {
                clip-path: ellipse(1200px 100% at 50% 100%);
            }

            @include ipadpro {
                clip-path: ellipse(1000px 100% at 50% 100%);
            }

            @include ipad {
                clip-path: ellipse(900px 100% at 50% 100%);
            }

            @include mobile {
                clip-path: ellipse(800px 100% at 50% 100%);
            }

            @include small {
                clip-path: ellipse(700px 100% at 50% 100%);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 490px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: -1;
        }

        @include ipadpro {
            padding-top: 4*$ref-size;
        }

        @include ipad {
            padding-top: 0px;
        }

        @include mobile {
            padding-top: 0*$ref-size;
            padding-bottom: 0px
        }

        .sideStrips {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            z-index: 3;
        }

        .searchComponentWrapper {
            display: flex;
            justify-content: space-between;

            @include ipad {
                display: block;
            }
        }

        @include ipad {
            margin-top: -50px;
        }
    }
}