@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';


.twoImageSection {
    padding: 80px 0;

    .bottom {
        @include mobile {
            margin-top: 40px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        margin: auto;
        object-fit: cover;
    }
}