@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.TableWraper{
    overflow-x: scroll;
    width: 100%;
    .table {
        min-width: 1300px;
        padding: 0 50px;
        @include mobile {
            min-width: unset;
        }
    
        @include small {
            padding: 0 20px;
        }
    
        @include exsmall {
            padding: 0 10px;
        }
    
        .heading {
            color: #010101a6;
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: #f5f5f5;
            border-radius: 6px;
            font-weight: bold;
            padding-left: 28px;
            padding-right: 15px;
            font-size: 16px;
            line-height: 20px;
            z-index: 1;
    
            @include mobile {
                display: none;
            }
    
            div {
                p {
                    background-color: #f5f5f5;
                    text-align: left;
                    padding: 0;
                    font-size: 18px;
                }
    
                div {
                    background-color: #f5f5f5;
                }
    
                @include mobile {
                    width: 20%;
                    text-align: left;
                }
    
                @include exsmall {
                    width: 100%;
                    margin-bottom: 12px;
                    flex: none;
                }
    
            }
    
        }
    
        .listing {
            color: #707b87;
            position: relative;
            border: 1.5px solid transparent;
            filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.16));
            border-bottom: 1px solid #c4c4c4;
            padding: 10px 5px;
    
            >div {
                padding-top: 25px;
                padding-bottom: 25px;
    
                @include mobile {
                    display: block;
                    padding-top: 0;
                    padding-bottom: 20px;
                    width: 100%;
                    text-align: center;
                }
            }
    
            >div:first-child {
    
                @include mobile {
                    text-align: center;
                    padding-top: 20px;
                }
            }
    
            >div:nth-child(2) {
                @include mobile {
                    width: 50%;
                }
            }
    
            >div:nth-child(3) {
                @include mobile {
                    width: 50%;
                }
            }
    
            >div:nth-last-child(2) {
                @include mobile {
                    flex: none;
                }
            }
    
            span {
                display: inline-block;
                padding: 6px 27px;
                border-radius: 35px;
    
                @include mobile {
                    padding: 6px 35px;
                }
            }
    
            .active {
                background-color: #EDF8F3;
                color: #3E9267;
            }
    
            .inactive {
                background-color: #F1F1F1;
                color: #B3BCC6;
            }
    
            .draft {
                background-color: #d1e0ff;
                color: #3b3b3b;
                padding: 6px 27px;
            }
    
            .preview {
                background-color: #f2c6ff;
                color: #650183;
            }
    
            .expired {
                background-color: #B3BCC6;
                color: #F1F1F1;
            }
    
            .filled {
                background-color: #aadcf0;
                color: #1a6685;
            }
    
            .hoverSection {
                color: #707b87;
                background: #fff;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                display: none;
                padding: 25px 12PX 14px 12px;
                border-radius: 8px;
    
                @include mobile {
                    height: 100%;
                    text-align: center;
                }
    
                >div {
                    padding-left: 0;
                    width: 48%;
    
                    @include mobile {
                        width: 100%;
                    }
                }
    
                div:first-child {
                    color: #707b87;
                    width: 41.6%;
                    padding-left: 28px;
    
                    @include mobile {
                        width: 100%;
                        text-align: center;
                    }
                }
    
                div {
                    padding: 0;
                }
            }
    
            .links {
                display: flex;
                justify-content: right;
                align-items: center;
    
                @include mobile {
                    margin-top: 40px;
                    justify-content: center;
                }
    
                @include small {
                    margin-top: 25px;
                    ;
                    display: block;
                }
    
                button,
                a {
                    border: none;
                    background: none;
                    padding: 0;
                    transition: 0.3s ease;
                    margin-left: 35px;
                    padding: 3px 40px;
                    border: 1px solid $lgbt-color-blue;
                    border-radius: 35px;
                    color: $lgbt-color-blue;
                    display: block;
    
                    @include ipadpro {
                        margin-left: 20px;
                    }
    
                    @include ipad {
                        margin-left: 10px;
                        padding: 3px 25px;
                    }
    
                    @include mobile {
                        width: 125px;
                        margin-top: 10px;
    
                    }
    
                    @include small {
                        margin: 0 auto;
                        margin-top: 5px;
                    }
    
                }
    
                .delete {
                    background: #FFE5E5;
                    color: #E86060;
                    border-color: #FFE5E5;
                }
            }
        }
    
        .listing.desktopListing {
            padding-left: 28px;
            padding-right: 15px;
        }
    
    
        .listing.desktopListing:hover {
            border: 1.5px solid #bcc2d1;
            border-radius: 5px;
            filter: drop-shadow(0px 2px 7.5px rgba(0, 0, 0, 0.16));
        }
    
        .listing.desktopListing:hover .hoverSection {
            display: flex;
    
            @include mobile {
                display: block;
            }
        }
    
        .countRow {
            border: unset;
            background-color: #00000000;
            padding-left: 0px;
            display: flex;
            font-size: 14px;
    
            .count.row {
                padding: 0 6px 0 0;
            }
        }
    
        .countParent {
            padding: 0 3px 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        .Lgbtjobs {
            color: #365bb8;
        }
    
        .Bmejobs {
            color: #b94d7f;
        }
    
        .Disabilityjob {
            color: #00a0e9;
        }
    
        .Neurodiversityjobs {
            color: #21b193;
        }
    }
}

.noJobs{
    text-align: center;
    font-size: 20px;
}