@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.skeleton {
    display: block;
    width: 60%;

    .jobCardWrap {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 20px;
        padding: 30px;
        -webkit-mask-image: none;

        .skeletonTop {
            display: flex;
            justify-content: space-between;
            height: 100px;

            .topLeft {
                width: 65%;

                .title {
                    width: 100%;
                    margin-bottom: 10px;

                    span {
                        width: 100%
                    }
                }

                .points {
                    display: flex;
                    justify-content: space-between;

                    .left {
                        display: block;
                        width: 49%;

                        span {
                            display: block;
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }

                    .right {
                        display: block;
                        width: 49%;

                        span {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }

            .topRight {
                width: 30%;
                text-align: right;

                .imgPlaceholder {
                    width: 200px;
                    height: 100px;
                    border-radius: 20px;

                    @include ipadpro {
                        width: 150px;
                        height: 75px;
                    }

                    @include ipad {
                        width: 110px;
                        height: 65px;
                    }

                    @include exsmall {
                        width: 100px;
                        height: 55px;
                    }

                    @include xxsmall {
                        width: 65px;
                    }
                }
            }

            @include ipad {
                height: 70px;
            }
        }

        .skeletonBottom {
            margin-top: 10px;
            width: 100%;
            text-align: right;

            span {
                width: 20%;
            }

            @include ipad {
                margin-top: 0px;
            }
        }
    }

    @include ipad {
        width: 100%
    }
}