@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.gridWrapper{
    margin: 3rem -30px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include ipad{
        margin: 2rem -30px 0;
    }
    @include mobile{
        margin: 2rem -15px 0;
    }
    @include small{
        margin: unset;
        margin-top: 1.5rem;
    }

    .companyItemWrap{
        flex-basis: 25%;
        max-width: 25%;
        padding: 0 30px 60px;
        &:nth-last-child(-n + 4) {
            padding-bottom: 0px;
        }
        @include ipad{
            flex-basis: 33.33%;
            max-width: 33.33%;
            &:nth-last-child(-n + 4) {
                padding-bottom: 60px;
            }
            &:nth-last-child(-n + 3) {
                padding-bottom: 0px;
            }
        }
        @include mobile{
            padding: 0 15px 30px;
        }
        @include small{
            flex-basis: 50%;
            max-width: 50%;
            padding: 0 20px 40px;
            // &:nth-child(even){
            //     padding-right: 0;
            // }
            &:nth-last-child(-n + 4) {
                padding-bottom: 30px;
            }
            &:nth-last-child(-n + 3) {
                padding-bottom: 30px;
            }
            &:nth-last-child(-n + 2) {
                padding-bottom: 0px;
            }
        }
    }
}