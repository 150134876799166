@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.alertFrequecncy {
    padding: 36px 0;
    border-bottom: 1px solid #aaa;

    .Title {
        h2 {
            color: var(--primary-color);
            margin: 0 0 1.5em 0;
            font-weight: bold;
            font-size: 1.5em;
        }
    }

    .checkBoxSec {
        h3 {
            margin: 0 0 2em 0;
        }

        .checkBoxes {
            display: flex;
            justify-content: left;

            .checkBox {
                margin: 0 10px;

                label {
                    margin: 0px 10px;
                }
            }
        }
    }
}