@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.tick{
    color: $lgbt-color-green;
}
.times{
    color: $lgbt-color-red;
}

.tableWrapper {

    @include small{
        overflow-x: auto;
    }

    .status{
        font-weight: bold;
    }

    .Active {
        color: #3E9267;
    }

    .Expired {
        color: #9e9e9e;
    }

    .Deactivated {
        color: rgb(236, 86, 86);
    }
}