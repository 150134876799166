@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.Wrapper {

    margin: 20px 0px;

    .pagination {
        display: flex;
        justify-content: center;

        a {
            background-color: var(--primary-color);
            margin-right: 5px;
            margin-left: 5px;
            width: 35px;
            height: 35px;
            border-radius: 30px;
            border: unset;
            color: #ffff;
            display: flex;
            justify-content: center;
            align-items: center;
            @include small(){
                width: 26px;
                height: 26px;
                font-size: 13px;
            }
        }

        .active {
            background-color: var(--secondary-color);
        }
    }
}