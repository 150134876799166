@import '../../../assets/styles/mixins';

.contactPage {
    background: #f9f9f9;
    position: relative;
    overflow: hidden;
    padding: 5*$ref-size 0;
    padding-top: 0%;

    h1 {
        text-align: center;
        padding: 2*$ref-size 0;
    }

    .sideStrips {
        position: absolute;
        z-index: 2;
        top: 65%;
        right: 0;
        transform: translate(65%, -50%);
    }

    @include min-hd {
        padding: 4*$ref-size 0;
        padding-top: 0%;
    }

    @include laptop {
        padding: 4*$ref-size 0;
        padding-top: 0%;
    }

    @include mobile {
        padding: 0*$ref-size;
    }
}