@import "../../assets/styles/variables";

.decorWrapper {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 4px;
    border-radius: 20px;
    overflow: hidden;
    ;

    div {
        flex-grow: 1;

        &.white {
            background-color: #ffff;
        }

        &.green {
            background-color: #21B193;
        }

        &.black {
            background-color: #000000;
        }
    }
}