@import '../../assets/styles/variables';

.jobContent {
    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.2em;
    }

    ul {
        margin: 1em 0;

        li {
            margin-bottom: 1em;
            list-style-type: none;
            position: relative;
            padding-left: 26px;

            &::before {
                content: "";
                position: absolute;
                display: inline-block;
                top: 13px;
                left: 0;
                width: 22px;
                height: 20px;
                clip-path: path("M9.464,4.016a3,3,0,0,1,5.073,0L21.093,14.4A3,3,0,0,1,18.557,19H5.443a3,3,0,0,1-2.536-4.6Z");
                transform: translateY(-78%) rotate(90deg);
                background-color: var(--primary-color);
                scale: 0.7;
            }
        }
    }
}