@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.popularJobsSec {
    padding: $ref-size 0 0;

    h2 {
        margin-top: 0;
    }

    div {
        width: 100% !important;
    }

    figure {

        @include small {
            max-width: unset;
        }
    }

    .jobBgWrapper {
        padding: 70px 0;
        background-color: #F9F9F9;

        a {
            figure {
                @include mobile {
                    width: 170px;
                    height: 85px;
                }

                @include exsmall {
                    width: 135px;
                    height: 80px;
                }
            }
        }
    }
}