@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.homeBanner {
    position: relative;
    z-index: 99;
    height: 88vh;

    @include mobile {
        height: auto;
        background-color: var(--primary-color);
    }

    .animWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: var(--primary-color);
        padding: 0px !important;

        @include laptop {}

        @include ipadpro {}

        @include ipad {}

        @include mobile {
            height: 33vh;
        }

        @include small {}

        @include mobileLand {}


        .downArrow {
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translateX(-50%);
        }

        img {
            object-fit: cover;
        }

        .slick-list {
            height: 100%;
        }

        .slick-track {
            height: 100%;
        }

        .slick-slide {
            height: 100%;
        }

        div {
            height: 100%;
        }

    }

    .bannerCntWrapper {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .headline {
            font-size: 2.7em;
            color: #fff;
            margin-bottom: 0.2em;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 2.5em;
            }

            @include ipad {
                font-size: 2.3em;
            }

            @include mobile {
                margin: 0;
                padding-bottom: 25px;
                font-size: 2em;
                text-shadow: unset;
                font-weight: 600;
            }
        }

        .cnt {
            font-size: 1.2em;
            color: #fff;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 1.1em;
            }

            @include ipad {
                font-size: 1em;
            }
        }

        @include mobile {
            padding: 25px;
            transform: translate(0%, 0%);
            top: 88%;
            position: unset;
        }
    }
}