// Import variables and mixins\
@import './variables';
@import './mixins';

// Apply CSS reset or normalization
@import './reset';

// Global styles
body {
    font-family: "Varela Round", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: -0.01em;
    font-size: 18px;

    @include ipadpro {
        font-size: 16px;
    }

    @include mobile {
        font-size: 14px;
    }
}


h1 {
    display: block;
    font-size: 2.2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    letter-spacing: -0.04rem;

    @include ipadpro {
        font-size: 1.8em;
    }

    @include mobile {
        font-size: 1.5em;
    }
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    letter-spacing: -0.04rem;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    letter-spacing: -0.04rem;
}

h4 {
    display: block;
    font-size: 1em;
    margin-bottom: 1em;
}

h5 {
    display: block;
    font-size: .83em;
    margin-bottom: 1em;
}

h6 {
    display: block;
    font-size: .67em;
    margin-bottom: 1em;
}

p {
    margin-bottom: 1em;
}

a {
    color: #000;
}

img {
    max-width: 100%;
    height: auto;
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 117px;
    @include ipadpro{
        padding-top: 114px;
    }
    @include small{
        padding-top: 80px;
    }
}

// Slick slider
.slick-slider {
    padding: 0 90px;

    @include ipadpro {
        padding: 0 60px;
    }

    @include mobile {
        padding: 0 30px;
    }

    @include small {
        padding: 0;
    }

    .slick-track {
        display: flex;
        align-items: center;

        .slick-slide {
            &>div {
                display: flex;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        width: 50px;
        height: 50px;
        transition: 0.3s ease-in-out;
        transform-origin: center;

        @include mobile {
            width: 30px;
            height: 30px;
        }

        &::before {
            content: none;
        }

        &:hover {
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
            transform: translateY(-50%) scale(1.1);
        }

        &.slick-next {
            left: unset;
            right: 0;

            .slick-arrow-icon {
                transform: rotate(180deg);
            }
        }

        .slick-arrow-icon {
            width: 100%;
            height: 100%;
            scale: 0.7;
            color: var(--primary-color);
        }
    }
}

.rdw-option-active {
    background-color: #bcb9b9 !important;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0 !important;
}

.css-13cymwt-control {
    border-color: #000 !important;
}

#mic-init-access-tool::-webkit-scrollbar {
    display: none;
}

body #mic-init-access-tool .mic-access-tool-box {
    background-color: #53a16a;
    width: 410px;

    @include small {
        width: 100%;
    }

    .mic-access-tool-box-header {
        padding: 30px;
    }

    #mic-access-tool-box-close-button {
        width: 28px;
        height: 28px;
        border: 2PX solid #fff;
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        position: absolute;
        right: 30px;
        top: 30px;

        span {
            font-weight: bold;
            font-size: 15px !important;
            color: #fff;
            line-height: .1px !important;
        }
    }

    .mic-access-tool-box-header h2 {
        font-size: 30px !important;
        text-transform: none;
        margin: 0;
    }

    #mic-toolbox-disable-buttons-keyboard,
    #mic-toolbox-disable-buttons-animations {
        border-color: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        background: #ffffff;
        margin-top: 10px;
        padding-left: 25px;
        padding-right: 25px;
        text-align: left;

        span {
            color: #53a16a;
            font-size: 18px !important;
        }

        &:hover {
            background-color: #53a16a !important;

            span {
                color: #fff;
            }
        }

    }

    #mic-toolbox-content-control-block {
        max-width: 100%;
        margin: 0;
        background: #fff;
        padding: 30px;
        text-align: left;

        span.mic-subtitle-span {
            text-align: left;
            font-size: 18px !important;
            font-weight: bold;
            color: #2b3c50;
            padding-bottom: 15px;
            display: block;
        }

        .mic-buttons-block {
            padding-bottom: 16px;


            button {
                border-color: #ebebeb;
                border-width: 1px;
                border-style: solid;
                border-radius: 5px;
                width: 100px;
                height: 95px;
                margin-right: 9px;
                padding: 10px;

                @include mobile {
                    margin-right: 0;
                    width: 32%;
                }

                svg {
                    font-size: 22px !important;
                    color: #9ca2ae;
                }

                span {
                    color: #2b3c50;
                    line-height: 20px !important;
                    font-weight: normal;

                }

                &:hover {
                    border-color: #53a16a;
                    background: none !important;
                    filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.16));

                    span,
                    svg {
                        color: #53a16a !important;
                    }

                }

                #mic-toolbox-contrast-hard {
                    margin: 0;
                }
            }

            button.vi-enabled {
                background-color: #53a16a !important;

                span,
                svg {
                    color: #fff !important;
                }

                &:hover {
                    background-color: #53a16a !important;
                }
            }
        }

        #mic-toolbox-disable-buttons-reset-all {
            border-radius: 5px;
            background: #53a16a;
            color: #fff;
            text-align: left;
            padding: 8px 27px;
            border: 1px solid #53a16a;

            span {
                color: #fff;
                line-height: 20px !important;
            }

            &:hover {
                background: #fff !important;

                span {
                    color: #53a16a;
                }
            }
        }
    }
}