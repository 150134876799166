@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.button {
    color: #fff;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 12px 25px;
    border-radius: 30px;
    font-size: 1em;
    display: inline-block;
    transition: 0.3s ease-in-out;
    text-align: center;

    @include mobile {
        padding: 10px 20px;
    }

    &:hover {
        background-color: #fff;
        color: var(--primary-color)
    }
}