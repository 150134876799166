@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/variables";

.genericEmployer {
    u {
        a {
            color: var(--primary-color);
            text-decoration: underline;
        }
    }

    a {
        color: var(--primary-color);
    }
}

.company-banner {
    background-color: var(--primary-employer-color);
    position: relative;

    @include hd {
        padding-top: 35px;
    }

    .banner-sub {
        display: flex;
        align-items: center;

        @include ipadpro {
            align-items: end;
        }

        .banner-left {
            @include ipadpro {
                padding-bottom: 40px;

                @include mobile {
                    padding-bottom: 0;
                }
            }

            .logo {
                width: 250px;
                height: 125px;
                background-color: var(--logo-bg);
                border-radius: 19px;
                margin-bottom: 43px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include ipad {
                    width: 200px;
                    height: 100px;
                }

                @include xxsmall {
                    width: 150px;
                    height: 75px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    padding: 5px;
                    object-fit: contain;
                }
            }

            h1 {
                color: var(--text-color);
                font-size: 55px;
                line-height: 1.2;
                font-weight: 500;

                @include laptop {
                    font-size: 45px;
                }

                @include ipadpro {
                    font-size: 40px;
                }

                @include mobile {
                    font-size: 35px;
                }

                br {
                    @include ipadpro {
                        display: none;
                    }
                }
            }
        }

        .banner-right {
            position: relative;
            z-index: 5;

            img {
                width: 100%;
                display: block;
            }
        }
    }
}

.tab-sec {
    margin: 50px 0;

    .tab {
        .tabs-wrapper {
            display: flex;
            overflow-x: auto;

            .tablinks {
                background-color: #ffff;
                flex: 1;
                border: 1px solid #e7e7e7;
                width: 100%;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                white-space: nowrap;
                border-radius: 5px 5px 0px 0px;
                margin: 0;
            }

            .tablinks.active {
                background-color: var(--primary-employer-color);
                border-bottom: 5px solid var(--secondary-employer-color);
            }
        }

        .TabTitle {
            text-align: center;
            padding: 10px 5px 10px 5px;
            background-color: var(--primary-employer-color);
            border-radius: 15px 15px 0px 0px;
            font-size: 25px;
        }
    }

    .tabWrapper {
        .tabCnt {
            display: none;
            padding: 50px 40px 50px 40px;
            background-color: var(--primary-employer-color);
            overflow: auto;
            border-radius: 0px 0px 15px 15px;
        }

        .showCnt {
            display: block;
        }
    }

    li {
        list-style: disc;
        margin-left: 20px;
    }
}

.video-sec {
    padding: 80px 0px;

    @include ipad {
        padding: 45px 0px;
    }

    background-color: #f6f6f6;

    .video-sub {
        align-items: center;

        .video-left {
            iframe {
                width: 100%;
                height: 422px;
                border-radius: 15px;

                @include ipadpro {
                    height: 250px;
                }

                @include ipadpro {
                    height: 190px;
                }

                @include mobile {
                    height: 375px;
                }

                @include small {
                    height: auto;
                }
            }
        }

        .video-right {
            margin-left: 30px;

            @include mobile {
                margin-left: 0;
                margin-top: 30px;
            }

            p {
                color: #010101;
                font-size: 20px;
                line-height: 1.7;

                @include ipadpro {
                    font-size: 17px;
                }
            }
        }
    }

    &.reverse {
        background-color: var(--primary-employer-color);

        @include ipad {
            padding: 30px 0px 60px 0px;
        }

        .video-sub {
            flex-direction: row-reverse;

            h2 {
                font-size: 32px;
                font-weight: 500;
                color: var(--text-color);
            }

            p {
                color: var(--text-color);
            }


            .video-right {
                margin-left: 0px;
                margin-right: 30px;
            }
        }
    }

    li {
        list-style: disc;
        margin-left: 20px;
    }
}

.full-img {
    img {
        padding: 10px 0px 10px 0px;
        display: block;
        width: 100%;
    }
}

.who-heading {
    padding: 76px 0px;

    @include ipad {
        padding: 60px 0px;
    }

    @include mobile {
        padding: 40px 0px;
    }

    @include small {
        padding: 15px 0px 50px 0px;
    }

    h2 {
        font-size: 32px;
        color: #010101;
        line-height: 1.3;
        font-weight: 500;

        @include ipad {
            font-size: 28px;
        }
    }

    .who-left {
        margin-right: 50px;

        @include mobile {
            margin-right: 0;
        }
    }

    li {
        list-style: disc;
        margin-left: 20px;
    }
}

.life-heading {
    padding: 76px 0px;

    @include mobile {}

    padding: 40px 0px;

    .life-top {
        p {
            padding-top: 20px;
        }

        li {
            list-style: disc;
            margin-left: 20px;
        }
    }

    .secJobListing {
        width: 100%;
        padding-top: 100px;

        @include mobile {
            padding-top: 50px;
        }

        a {
            figure {
                @include mobile {
                    width: 170px;
                    height: 85px;
                }

                @include exsmall {
                    width: 135px;
                    height: 80px;
                }
            }
        }
    }
}

.bttn {
    width: 202px;
    height: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 1.3;
    color: var(--primary-employer-color);
    border: 1px solid var(--primary-employer-color);
    border-radius: 5px;
    margin-top: 40px;
    transition: 0.1s ease-in-out;
    background-color: #ffff;

    &:hover {
        background-color: var(--primary-employer-color);
        color: var(--text-color);
        border-color: var(--secondary-employer-color);
    }
}