@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.ListTitle {
    text-align: center;
    font-size: 2em;
    margin-bottom: 30px;
    font-weight: bold;
    color: rgb(121, 121, 121);

    @include mobile {
        font-size: 1.5em;
    }
}