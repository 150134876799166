@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.sort_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px 30px 50px;
    @include ipad(){
        max-width: 580px;
    }
    @include mobile(){
        max-width: 230px;
    }
    @include ipad() {
        display: block;
    }
    @include mobile(){
        padding: 0 ;
    }

    .form-search {
        position: relative;

        @include small() {
            padding-bottom: 7px;
        }

        .search-icon {
            position: absolute;
            @include max-wid($max: 20px);
            left: 12px;
            top: 12px;
            @include mobile(){
                top: 33px;
            }
        }

        input {
            outline: none;
            border: 0;
            padding: 7px 6px 7px 32px;
            @include borders($b: 20px);
            border: 2px solid $primary-blue-color;
            max-width: 100%;
            @include ipad(){
                width: 100%;
            }
            @include mobile() {
                margin-top: 20px;
            }
        }
    }

    .sortFilter_contents {
        display: flex;
        @include ipad(){
         margin-top: 7px;
        }
        @include mobile(){
            display: block;
            padding-bottom: 10px;
        }
        .sort_btn {
            position: relative;

            &:first-child {
                margin-right: 10px;
                @include mobile(){
                    margin:0 0 7px 0;
                }
            }

            @include ipad() {}

            button {
                display: flex;
                align-items: center;
                cursor: pointer;
                border: 2px solid $primary-blue-color !important;
                @include common_btn($wid: 230px, $high: 50px, $bg: white, $rd: 50px);
                padding: 15px;
                font-size: 18px;
                color: $primary-blue-color;
                justify-content: space-between;

                @include ipad() {
                    @include common_btn($wid: 230px, $high: 45px, $bg: white, $rd: 50px);
                    font-size: 16px;
                }
                &:hover {
                    cursor: pointer;
                }
            }

            .sort_contents {
                position: absolute;
                margin-top: 5px;
                background-color: white;
                padding: 10px 0;
                border-radius: 20px;
                width: 100%;
                box-shadow: 0 2px 14px -9px;
                z-index: 100;

                li {
                    color: black;
                    font-weight: bold;

                    a {
                        display: block;
                        padding: 10px 15px;
                        border-bottom: 1px solid #ccc;
                        width: 100%;
                        text-align: left;
                    }

                    &:last-child {
                        a {
                            border: none;
                        }

                    }
                }
            }
        }
    }

    .rotated {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
    }

}