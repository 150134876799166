@import '../../../assets/styles/mixins';

footer {
    background-color: var(--footer-background);
    color: #fff;
    position: relative;
    z-index: 1;

    .lStrips {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotateY(180deg);
        height: 100%;
        max-width: 100%;
    }

    .rStrips {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        max-width: 100%;
    }

    .wrapper {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include mobile {
            padding-bottom: 0;
        }

        @include small {
            padding-top: 40px;
            text-align: center;
        }

        .subscribeWrapper {
            float: left;
            width: 49%;
            padding-bottom: 4rem;

            @include small {
                float: none;
                width: 100%;

            }

            p {
                width: 400px;

                @include ipad {
                    width: 100%;
                }

                a {
                    color: #fff
                }
            }
        }

        .textContent {
            margin-bottom: 2rem !important;
            width: 87%;

            @include small {
                margin: 0 auto;

            }
        }

        .button {
            background-color: #fff;
            color: #2A373B;
            font-weight: bold;
            border-color: #fff;
            padding: 10px 28px;
            border-radius: 10px;

            @include exsmall {
                padding: 5PX 20px;
            }

            &:hover {
                background-color: #2A373B;
                color: #fff;
            }
        }

        .menuLinks {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 2rem 0;

            @include mobile {
                margin: 3rem 0;
                padding-left: 80px;
            }

            @include small {
                margin-top: 0;
                padding-left: 0;
            }

            .menuLink {
                flex-basis: 50%;
                max-width: 50%;
                text-align: left;
                margin-bottom: 1.5rem;

                a {
                    color: #fff;
                    transition: 0.3s ease;

                    @include small {
                        display: block;
                        text-align: center;
                    }

                    &:hover {
                        color: $lgbt-color-blue;
                    }
                }

                @include mobile {
                    flex-basis: 100%;
                    max-width: 100%;
                }

                @include small {
                    flex-basis: 50%;
                    max-width: 50%;
                }

                @include exsmall {
                    flex-basis: 100%;
                    max-width: 100%;
                }

            }
        }

        .socLinks {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .socLink {
                margin: 0 0.3rem;

                a {
                    transition: 0.3s ease;

                    &:hover {
                        opacity: 0.6;
                    }

                    svg {
                        width: 40px;
                        height: 40px;
                        color: #fff;
                    }
                }


                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    .copyright {

        p {
            text-align: center;
            padding: 1.5rem 0;
            margin-bottom: 0;
            color: #bbb;
        }
    }

}