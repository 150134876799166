@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.searchWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 100px;
    border: 1.5em solid #00000088;
    margin-top: 3.75em;

    @include ipadpro {
        width: 100%;
        border: 1.5em solid #00000088;
    }

    @include ipad {
        width: 100%;
        border: 1.3em solid #00000088;
    }

    @include mobile {
        background-color: #00000088;
        border: 0;
        padding: 1.4em;
        border-radius: 40px;
        margin-top: 1em;
    }

    form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8em 0.8em 0.8em 1em;
        border-radius: 70px;
        background: #fff;
        width: 100%;

        @include ipad {
            padding: 0.7em 0.7em 0.7em 1em;
        }

        @include mobile {
            background-color: transparent;
            padding: 0;
            display: block;
        }

        .inputOuter {
            width: 100%;
            position: relative;

            @include mobile {
                display: inline-block;
                width: 67%;
                float: left;
            }

            .inputWarp {
                @include mobile {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 150%;
                    background-color: #8A9193;

                    @include mobile {
                        content: none;
                    }
                }
            }
        }

        .inputWarp {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 20px;

            @include ipad {
                margin-right: 0px;
            }

            @include mobile {
                background-color: #fff;
                padding: 1em;
                border-radius: 40px;
                margin-bottom: 1.5em;
            }

            @include small {
                padding: 0.7em;
            }

            input {
                border: none;
                outline: none;
                width: 100%;
                padding: 0 0.8rem;
                color: #8A9193;

                @include ipad {
                    padding: 0 0.6rem;
                }

                &::placeholder {
                    color: #555;
                }
            }

            svg {
                width: 38px;
                height: 38px;
                color: #8A9193;
                margin-left: 20px;

                @include mobile {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
            }
        }

        .searchBtn {
            border: 0;
            outline: none;
            padding: 13px 43px;
            background-color: var(--primary-color);
            border: 2px solid var(--primary-color);
            color: #fff;
            border-radius: 30px;
            font-family: inherit;
            transition: 0.2s ease-in-out;
            font-weight: bold;

            @include ipad {
                padding: 10px 24px;
            }

            @include mobile {
                padding: 0.6em;
                border-radius: 50px;
                display: inline;
                float: right;
                font-size: 1.2em;
                width: 28%;
            }

            @include small {
                font-size: 1em;
            }

            &:hover {
                background-color: #fff;
                color: var(--primary-color);
            }
        }

        .clearBtn {
            border: unset;
            background: transparent;
            width: 40px;

            svg {
                color: #555;
                width: 100%;
                transition: 0.2s ease;
            }

            &:hover {
                svg {
                    color: #000;
                }
            }
        }
    }
}