@import './variables';

@mixin uhd {
    @media (max-width: #{$UHD-width}) and (min-width:1921px) {
        @content;
    }
}

@mixin hd {
    @media (max-width: #{$hd-width}) {
        @content;
    }
}

@mixin min-hd {
    @media (max-width: #{$mini-hd-width}) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: #{$laptop-width}) {
        @content;
    }
}


@mixin ipadpro {
    @media (max-width: #{$ipadpro-width}) {
        @content;
    }
}

@mixin ipad {
    @media (max-width: #{$ipad-width}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin small {
    @media (max-width: #{$mobile-small-width}) {
        @content;
    }
}

@mixin exsmall {
    @media (max-width: #{$mobile-ex-small-width}) {
        @content;
    }
}

@mixin xxsmall {
    @media (max-width: #{$mobile-2x-small-width}) {
        @content;
    }
}

@mixin mobileLand {
    @media (max-width: #{$mobileland-width}) and (max-height: #{$mobileland-height}) {
        @content;
    }
}
// ======================================
// ==============common styles===========
// ======================================
@mixin common_btn($wid,$high,$bg,$rd){
    min-width:$wid;
    height: $high;
    background-color:$bg;
    border-radius: $rd;
}
@mixin max-wid($max){
    max-width: $max;
}
@mixin borders($b){
    border-radius: $b;
}