@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.companySearchWrap{
    .companySearchForm{
        display: flex;
        align-items: center;
        @include mobile{
            flex-direction: column;
        }
        .inputWrap{
            background-color: var(--secondary-color);
            &:last-child{
                margin-right: 0;
            }
            @include mobile{
                margin-right: 0;
                margin-bottom: 20px;
                padding: 0.8rem 0.8rem 0.8rem 1rem;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            input{
                color: #fff;
                &::placeholder{
                    color: #ffffff99;
                }
            }
            .icon{
                color: #fff;
            }
        }
    }
}