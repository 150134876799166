.fieldOuter{
    label{
        margin-bottom: 1.3em;
        h4{
            margin-bottom: 0;
            font-weight: bold;
        }
        display: block;
        .checkBoxWrap{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            input{
                width: unset !important;
                margin-right: 10px;
            }
            span{
                font-size: 0.9em;
            }
        }
    }
}