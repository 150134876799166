.speech-button {
    position: absolute;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 999;
    transform: translateX(-50%);
}

.speech-button.speaking {
    animation: pulse 0.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1) translateX(-50%);
    }

    50% {
        transform: scale(1.1) translateX(-50%);
    }

    100% {
        transform: scale(1) translateX(-50%);
    }
}