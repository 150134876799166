@import '../../../assets/styles/mixins';

.aboutUsPageWrapper {

    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        color: var(--secondary-color);

        @include mobile {
            font-size: 30px;
        }
    }

    p {
        font-size: 20px;

        @include mobile {
            font-size: 18px;
        }

        @include xxsmall {
            font-size: 16px;
        }
    }

    .banner {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% -180px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            max-height: 75vh;
            z-index: -1;
        }
    }

    .neuroBanner {
        &::before {
            content: '';
            width: 100%;
            height: 60vh;
            background: var(--primary-color);
            position: absolute;
            top: 0;
            z-index: -1;
        }
    }

    .bannerCard {
        top: 145px;
        background-color: #f9f9f9;
        border-radius: 70px;
        padding: 185px 185px 250px 185px;
        left: 50%;
        margin: 145px 0px 100px 0px;

        @include laptop {
            padding: 50px;
        }

        @include mobile {
            margin: 145px 0px 35px 0px;
        }

        @include exsmall {
            padding: 30px 30px;
        }

        h1 {
            font-weight: bold;
            text-align: center;
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;

            @include ipadpro {
                margin-bottom: 30px;
            }
        }
    }
}

.paragraphSection {
    .Description {
        font-size: 14px;
    }
}

.imageWrapper {
    z-index: -1;

    img {
        width: 100%;
    }
}

.linkedinBio {
    text-align: left;
    background-color: var(--secondary-color);
    color: #ffff;
    padding: 80px 0;

    .title {
        display: flex;
        justify-content: left;
        align-items: center;

        &::after {
            margin-left: 60px;
            content: '';
            height: 3px;
            width: 200px;
            background-color: #ffff;

            @include ipad {
                margin-left: 40px;
                width: 150px;
            }

            @include small {
                margin-left: 30px;
                width: 100px;
            }

            @include xxsmall {
                margin-left: 10px;
                width: 50px;
            }
        }
    }

    h2 {
        color: #ffff;
        text-align: left;
    }

    @include mobile {
        padding: 25px 0;
    }
}

.feedWrapper {
    padding: 80px 0px;

    @include mobile {
        padding: 25px 0px;
    }
}

.block {
    padding: 80px 0;

    @include small {
        padding: 40px 0;
    }
}