@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.companiesSecWrap{
    padding: 3em 0 6em;
    background-color: #fff;
    .companiesSecInner{
        @media (min-width: #{$ipadpro-width}) {
            max-width: 960px;
        }
    }
}