@import '../../assets/styles/mixins';

.link {
    display: inline-block;
    min-height: 86px;
    display: grid;
    justify-content: center;
    align-items: center;

    @include ipadpro {
        min-height: 60px;
    }

    @include small {
        min-height: 40px;
    }

    .logo {
        width: 350px;

        @mixin min-hd {
            width: 350px;
        }

        @include laptop {
            width: 250px;
        }

        @include ipadpro {
            width: 300px;
        }

        @include mobile {
            width: 80%;
        }

        @include small {
            width: 65%;
        }
    }
}