@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.homeBanner {
    position: relative;
    z-index: 99;

    .animWrapper {
        position: relative;
        width: 100%;
        height: 88vh;
        overflow: hidden;
        background-color: var(--primary-color);

        @include mobile {
            height: auto;
        }


        .downArrow {
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translateX(-50%);
        }


        /*Newly added*/

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.1);
            transform-origin: top right;

            @include mobile {
                height: auto;
                transform: scale(1.1) translateX(30px);
            }
        }
    }

    .bannerCntWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include mobile {
            padding-top: 25px;
            padding-bottom: 25px;
            position: relative;
            top: 100%;
            background-color: var(--primary-color);
            left: 0%;
            transform: translate(0%, 0%);
            max-width: 768px;
            margin: 0;
        }

        .headline {
            font-size: 2.7em;
            color: #fff;
            margin-bottom: 0.2em;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 2.5em;
            }

            @include ipad {
                font-size: 2.3em;
            }

            @include mobile {
                font-size: 2.2em;
                padding-bottom: 25px;
            }
        }

        .cnt {
            font-size: 1.2em;
            color: #fff;
            text-shadow: 0px 0px 20px #000000;

            @include ipadpro {
                font-size: 1.1em;
            }

            @include ipad {
                font-size: 1em;
            }
        }
    }
}

.employerBanner {
    .animWrapper {
        height: 75vh;

        img {
            @include mobile {
                height: 66vh;
            }

            @include exsmall {
                height: 80vh;
            }
        }
    }

    .bannerCntWrapper {
        @include mobile {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #00000000;
        }
    }
}

.privacyTerms {
    .animWrapper {
        height: 40vh;
        img {
            @include mobile {
                height: 40vh;
            }
        }
    }
    .bannerCntWrapper {
        @include mobile {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #00000000;
        }
    }
}
.jobAlert {
    .animWrapper {
        height: 40vh;
        img {
            @include mobile {
                height: 40vh;
            }
        }
    }
    .bannerCntWrapper {
        @include mobile {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #00000000;
        }
    }
}

