@import '../../assets/styles/mixins';

.promoSection {
    .container {
        .bannerWrapper {
            position: relative;
            min-height: 250px;
            z-index: 1;
            top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
                top: 20px;
            }
    
        }
    }

    position: relative;

}