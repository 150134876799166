@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.card {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(8, 8, 8, 0.16) 0px 3px 6px;
    margin: 25px 0px 25px 0px;
    border-radius: 20px;

    .cardLeft {
        width: 33%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include mobile {
            width: 50%;
        }
        figure {
            margin: 20px;
            border: 1px solid rgb(196, 196, 196);
            padding: 15px;
            border-radius: 20px;
            width: 200px;
            height: 100px;
            display: flex;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: auto;

                @include mobile {
                    max-width: 100px;
                }

                @include xxsmall {
                    max-width: 75px;
                }
            }

            @include mobile {
                margin: 10px;
                padding: 10px;
                width: 175px;
                height: 87px;
            }

            @include small {
                width: 150px;
                height: 75px;
            }

            @include exsmall {
                width: 130px;
                height: 65px;
            }
        }
    }

    .dataWrapper {
        display: flex;
        justify-content: space-between;

        width: 67%;

        .cardCenter {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #6f6f6f;

            h3 {
                font-size: 1.5em;
                font-weight: 500;

                @include small {
                    font-size: 1em;
                }
            }
        }

        .cardRight {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 50px;

            a {
                border: 2px solid var(--secondary-color);
                font-size: 1em;
                font-weight: bold;
                // padding: 10px;
                min-width: 150px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: var(--secondary-color);
                @include small(){
                    font-size: 14px !important;
                    min-width: 120px;
                    height: 40px;
                }

                @include xxsmall {
                    padding: 5px;
                }
            }

            @include mobile {
                padding: 0px;
            }
        }

        @include mobile {
            display: block;
            width: auto;
            padding: 15px;
            text-align: right;
        }
    }
}