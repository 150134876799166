@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.searchWrapper {
    display: flex;
    justify-content: center;
    width: 90%;
    border-radius: 100px;
    border: 1.5em solid #00000088;

    @include ipadpro {
        width: 100%;
        border: 1.5em solid #00000088;
    }

    @include ipad {
        width: 100%;
        border: 1.3em solid #00000088;
    }

    @include mobile {
        background-color: #00000088;
        border: 0;
        padding: 1.7em;
        margin-top: 3em;
    }

    @include small {
        padding: 1.5em;
    }

    form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8em 0.8em 0.8em 1em;
        border-radius: 70px;
        background: #fff;
        width: 100%;

        @include ipad {
            padding: 0.7em 0.7em 0.7em 1em;
        }

        @include mobile {
            background-color: transparent;
            padding: 0;
        }

        .inputWarp {
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 20px;

            @include ipad {
                margin-right: 0px;
            }

            @include mobile {
                background-color: #fff;
                padding: 1em;
                border-radius: 40px;
            }

            @include small {
                padding: 0.7em;
            }

            input {
                border: none;
                outline: none;
                width: 100%;
                padding: 0 0.8rem;
                color: #000;

                @include ipad {
                    padding: 0 0.6rem;
                }

                &::placeholder {
                    color: #555;
                }
            }

            svg {
                width: 38px;
                height: 38px;
                color: var(--decor-color-7);

                @include mobile {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .searchBtn {
            border: 0;
            outline: none;
            padding: 15px 35px;
            background-color: var(--decor-color-7);
            color: #fff;
            border-radius: 30px;
            font-family: inherit;
            transition: 0.2s ease-in-out;

            @include ipad {
                padding: 10px 24px;
            }

            @include mobile {
                padding: 1em 0.8em;
                border-radius: 50px;
                display: inline;
                font-size: 1.2em;
                width: 28%;
                margin-left: 1em;
            }

            @include small {
                font-size: 1em;
            }

            &:hover {
                background-color: var(--decor-color-4);
            }
        }
    }
}

.alphabetScale {
    margin-top: 30px;
    display: flex;

    @include ipadpro{
        display: block;
    }

    @include xxsmall {
        margin-top: 5px;
    }

    p {
        color: #ffff;
        margin: auto 5px auto 0px;
        font-size: 1.2em;
        font-weight: 400;
        text-shadow: 2px 4px 24px rgba(0, 0, 0, 0.37);
    }

    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 5px;
        border-radius: 10px;

        li {
            background-color: #ffff;
            margin: 2px;
            font-size: 15px;
            color: #000;
            border-radius: 40px;
            padding: 4px 10px;
            min-width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.37);
            -webkit-box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.37);
            -moz-box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.37);

            @include laptop {
                min-width: 27px;
                height: 27px;
                padding: 4px 5px;
            }

            @include ipadpro {
                min-width: 30px;
                height: 30px;
                margin: 3px;
                font-size: 10px;
                padding: 4px 9px;
            }
        }

        .selected {
            background-color: var(--decor-color-2);
            color: #ffff;
        }
    }
}