.editorWrapper{
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    height: 30%;
}
.editor{
    padding: 0 10px;
    height: 300px;
}
.toolbar{
    background-color: #eaeaea;
    border-bottom: 1px solid #000;
    margin-bottom: 0;
}
.inlineTools,
.listTool{
    background-color: #eaeaea;
    &>div{
        background-color: #eaeaea;
        border-color: #eaeaea;
        box-shadow: none !important;
        padding: 13px 8px;
        border-radius: 3px;
        transition: 0.07s ease-in-out;
        margin: 0 3px;
        &:hover{
            background-color: #cbcbcb;
        }
    }
}