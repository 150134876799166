@import '../../../../assets/styles/variables';

.skillsInputWrapper {
    .skillTags {
        padding: 10px;
        border: 1px solid;
        border-radius: 8px;
        position: relative;
        transition: 0.1s ease;
    }

    .isActive {
        border: 1px solid var(--primary-color);
    }

    .invalid {
        border: 1px solid $lgbt-color-red;
    }

    .selectedSkillsList {
        display: flex;
        flex-wrap: wrap;
    }

    .selectedSkillsListItem {
        margin: 0 5px 5px 0;

        &:last-child {
            margin-bottom: 15px;
        }

        button {
            border: 1px solid var(--primary-color);
            background-color: var(--primary-color);
            color: #fff;
            padding: 3px 30px 3px 8px;
            border-radius: 40px;
            position: relative;
            overflow: hidden;
            transition: 0.3s ease;

            &::after {
                content: '\00d7';
                color: #fff;
                background-color: var(--primary-color);
                position: absolute;
                right: 5px;
                top: 50%;
                width: 18px;
                height: 18px;
                line-height: 0.9;
                transform: translateY(-50%);
                transition: 0.3s ease;
                border-radius: 20px;
            }

            &:hover {
                &::after {
                    background-color: #fff;
                    color: var(--primary-color);
                }
            }
        }
    }

    .skillsInputField {
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
    }

    .skillsListBox {
        position: absolute;
        top: 105%;
        left: 0%;
        height: 260px;
        overflow: hidden;
        background-color: #fff;
        padding: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: baseline;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        z-index: 999;
    }

    .skillsListBoxItem {
        border: 1px solid var(--primary-color);
        padding: 5px 15px;
        border-radius: 30px;
        margin: 5px;
        cursor: pointer;
        transition: 0.1s ease;
        color: var(--primary-color);

        &:hover {
            background-color: var(--primary-color);
            color: #fff;
        }
    }

    .highlight {
        background-color: rgba(175, 199, 255, 0.417);
        color: var(--primary-color);
    }
}