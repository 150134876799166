.previewWrap{
    .continueEdit{
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
        background-color: var(--primary-color);
        color: white;
        border-radius: 35px; 
        padding: 10px 20px;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        transition: background-color 0.3s ease;
        svg{
            margin-left: 15px;
        }
    }
    .previewTemplate{
        position: relative;
    }
}