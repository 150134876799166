@import '../../../assets/styles/mixins';

.home{
    position: relative;
    overflow: hidden;
    .sideStrips{
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        &.sideStrips2{
            left: 0;
            right: unset;
            top: 70%;
            transform: translate(-50%, -50%);
        }
        &.sideStrips3{
            top: 80%;
        }
        @include laptop{
            display: none;
        }
    }
}