@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.signUp {
    padding: 6*$ref_size 0 8*$ref_size;
    flex-grow: 1;
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: fixed;
    background-attachment: fixed;

    .forOuter {
        display: flex;
        flex-direction: column;
        align-items: center;

        .formWrap {
            background-color: #fff;
            width: 80%;
            padding: 2.5em;
            border-radius: 40px;
            min-height: 30em;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;

            @include laptop {
                width: 80%;
            }

            @include ipadpro {
                width: 85%;
            }

            @include ipad {
                width: 100%;
                padding: 0;
            }

            h1 {
                text-align: center;
                margin-top: 0;
                margin-bottom: 1em;
                font-weight: bold;
            }

            form {
                display: flex;
                align-items: center;
                flex-direction: column;

                @include small {
                    display: flex;
                    align-items: center;
                }

                .textError {
                    color: $lgbt-color-red;
                }

                .forgotPw {
                    margin-bottom: 1.5em;
                    color: var(--primary-color);
                }

                .inputsOuterWrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: 0 -10px;

                    @include small {
                        display: block;
                        width: 100%;
                    }

                    .inputOuter {
                        flex-basis: 50%;
                        max-width: 50%;
                        padding: 0 10px;
                        margin-bottom: 1.3em;

                        @include small {
                            display: block;
                            max-width: 100%;
                        }
                    }
                    .linkedInUrl{
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }


                .submitWrap {
                    position: relative;
                    width: 50%;
                    background-color: var(--primary-color);
                    border-radius: 30px;
                    font-size: 1em;
                    text-align: center;
                    overflow: hidden;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .submitBtn {
                        padding: 0.7em 1.5em;
                        width: 100%;
                        color: #fff;
                        background: transparent;
                        border: none;
                        outline: none;
                        transition: 0.3s ease-in-out;


                        @include mobile {
                            padding: 10px 20px;
                        }

                        &:hover {
                            background-color: var(--primary-color);
                        }

                    }

                    .signUpLoader {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 50px;
                    }
                }
                .loginLink {
                    margin-top: 1.5em;
                    margin-bottom: 0;
                    display: block;

                    a {
                        color: var(--primary-color);

                        svg {
                            vertical-align: text-top;
                        }
                    }
                }

            }
        }
    }
}