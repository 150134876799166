@import '../../assets/styles/mixins';

.card {
    background-color: #ffff;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    margin: 0px 0px 20px 0px;
    padding: 30px;
    border-radius: 20px;

    .contentWrapper {
        display: flex;
        justify-content: space-between;

        .infoWrapper {
            width: 70%;

            .cardTitle {
                color: var(--primary-color);

                h3 {
                    color: var(--primary-color);
                    font-size: 1.5em;
                    font-weight: 700;
                    margin-top: 0px;
                    margin-bottom: 0.5em;
                }
            }

            .infoWrapperImage {

                .iconWrapper {
                    display: none;

                    @include ipad {
                        display: block;
                    }
                }

                .basicInfoWrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-right: 0.5em;

                    .basicInfo {
                        width: 50%;

                        &:nth-child(2) {
                            margin-left: 10px;

                        }

                        @include ipad {
                            &:nth-child(2) {
                                margin-left: 0px !important;
                            }
                        }

                        .optionWrapper {
                            display: flex;

                            align-items: flex-start p {
                                font-size: 20px;
                                margin-bottom: 0.5em;
                            }

                            .Icon {
                                color: var(--primary-color);
                                padding: 3px 7px 0px 0px;

                                max-width: 20px;
                                width: 100%;
                                height: 100%;
                            }

                            .Icons {
                                color: var(--primary-color);
                                padding: 3px 7px 0px 0px;
                                font-size: 25px;
                            }
                        }

                        @include ipad {
                            width: 100%;

                            .optionWrapper {
                                p {
                                    font-size: 18px;
                                }
                            }
                        }
                    }

                    @include ipad {
                        display: block;
                    }
                }

                @include ipad {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                }
            }

            @include ipad {
                width: 100%;
            }
        }

        .iconWrapper {

            a {
                width: 100%;
                height: 100%;

                figure {
                    margin-left: auto;
                    width: 190px;
                    height: 100px;
                    border: 1px solid #c5c5c5;
                    border-radius: 20px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    @include small {
                        width: 130px;
                        height: 75px;
                    }
                }
            }

            @include ipad {
                display: none;
            }
        }
    }

    .cardFooter {
        color: var(--primary-color);
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
        }
    }

    @include mobile {
        padding: 15px;
    }
}