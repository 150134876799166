@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.contents {
    margin-top: -1px;

    p {
        line-height: 37px;
    }

    padding: 80px 0;
    background-color: #F9F9F9;

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .imageWrapper {
            width: 49%;

            img {
                width: 100%;
                border-radius: 11px;
            }

            @include ipadpro {
                margin-bottom: 30px;
                width: 100%;
            }
        }

        .textContainer {
            width: 49%;
            padding-right: 9%;

            @include ipadpro {
                width: 100%;
                margin-bottom: 30px;
                padding-right: 0%;
            }
        }

        @include ipadpro {
            flex-direction: column-reverse;
        }
    }

    .bottom {
        padding: 50px 0px 0px 0px;

        @include ipadpro {
            padding: 0;
        }
    }

    @include small {
        padding: 40px 0;
    }
}