@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.inputWrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 20px;
    padding: 0.7em;
    padding-left: 1em;
    background: #fff;
    border-radius: 200px;

    @include mobile{
        padding: 0.6em;
        padding-left: 0.9em;
    }

    input {
        border: none;
        outline: none;
        width: 100%;
        padding: 0 0.8em;
        font-size: 1em;
        color: #000;
        background-color: transparent;

        &::placeholder {
            color: #555;
        }
    }

    svg {
        width: 38px;
        height: 38px;
        color: var(--decor-color-7);
        @include mobile{
            width: 30px;
            height: 30px;
        }
    }
}