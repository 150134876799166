.postFields{
    .dateField{
        &>div{
            padding: 10px 15px;
            border-radius: 10px;
        }
        .calendar{
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            overflow: hidden;
        }
        .clock{
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            overflow: hidden;
        }
        input{
            border: none !important;
            padding: 0 !important;
        }
    }
}
