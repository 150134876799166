@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

// ConfirmationPopup.module.scss
.popupBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .popupContainer {
        background-color: #fff;
        padding: 40px;
        border-radius: 8px;
        text-align: center;
        max-width: 550px;

        @include small {
            max-width: 80%;
        }

        .popupActions {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .confirmButton {
                background-color: var(--primary-color);
                color: #fff;
                padding: 15px 30px;
                font-weight: bold;
                border: none;
                cursor: pointer;
                margin: 10px;
                border-radius: 40px;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }

            .cancelButton {
                background-color: #FFB1B1;
                color: #000;
                font-weight: bold;
                padding: 10px 30px;
                border: none;
                cursor: pointer;
                margin: 10px;
                border-radius: 40px;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }


}