@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.containerForm {
  position: relative;
  padding: 2 * $ref-size 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: 1;
    top: 0;
    left: 0;
    transform: translate(-118%, -22%) rotateZ(10deg) scale(2);

    @include mobile {
      transform: translate(-110%, -22%) rotateZ(10deg) scale(2);
    }
  }

  .contactFormWrap {
    padding: 3 * $ref-size;
    border-radius: 40px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    z-index: 2;
    position: relative;

    @include mobile {
      padding: 2 * $ref-size;
      margin: 1 * $ref-size;
      position: relative;
      text-align: center;
    }

    .contactForm {
      .formGroup {
        display: flex;
        justify-content: space-between;

        .formField {
          width: 48%;
          position: relative;
        }

        @include mobile {
          display: inline;

          .formField {
            width: 100%;
          }
        }
      }

      .success_message {
        margin-top: 15px;
        color: green;
      }
      .error_message {
        margin-top: 15px;
        color: red;
      }

      .fullWidth {
        width: 100%;
      }

      .field {
        input,
        textarea {
          background: #dae9f8;
          border: none;
          margin-top: 40px;
          padding: 0.8em 1.5em;
          width: 100%;
          border-radius: 29px;
          outline: none;
          box-shadow: transparent;
        }
        .error {
          margin: none;
          color: #fb0000;
          position: absolute;
          padding-left: 20px;
          font-size: 14px;
        }

        @include mobile {
          textarea {
            border-radius: 15px;
          }
        }
      }
      button {
        color: #fff;
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
        padding: 12px 25px;
        margin-top: 3rem;
        border-radius: 30px;
        font-size: 1em;
        display: inline-block;
        transition: 0.3s ease-in-out;
        text-align: center;
        @include mobile {
          padding: 10px 20px;
        }
        &:hover {
          background-color: #fff;
          color: var(--primary-color);
        }
      }
    }
  }
}
