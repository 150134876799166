.suggestionList {
    position: absolute;
    z-index: 2;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    top: 105%;
    left: 0;
    width: 100%;

    .suggestionItem {
        padding: 10px;
        border-bottom: 1px solid #aaa;
        transition: 0.2s ease;
        cursor: pointer;
        text-align: left;

        &:hover {
            background-color: #eee;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.selected {
    background-color: #ddd;
}

.whiteLoader {
    position: absolute;
    filter: brightness(0.5);
    width: 30px;
    top: 105%;
}