@import '../../../../assets/styles/mixins';

.companyProfile{
    li{
        padding-left: 40px;
        position: relative;
        padding-bottom: 15px;
        @include mobile{
            padding-left: 30px;
        }
    }
    li::before{
        content: "";
        border-color: transparent #E51636;
        border-style: solid;
        border-width: 11px 0 11px 18px;
        display: block;
        height: 0;
        width: 0;
        left: 0;
        top: 0;
        position: absolute;
        @include mobile{
            border-width: 9px 0 9px 14px;
        }
    }
   
    img{
        width: 100%;
        display: block;
    }
    table{
        width: 100%;
    }
    .fullwidthOverlay{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: #E51636;
        opacity: 0.2;
    }
    .readMore{
          padding: 10px 40px;   
          background: none;
          border: 1px solid #E51636;  
          color: #E51636;  
          border-radius: 7px;
          @include mobile{
            padding: 7px 30px;
          }
             
    }
    .column{
        margin-bottom: 24px;
    }
    .banner{
        background: url('../../../../assets/images/post-office-banner.png');
        background-position: center;
        background-size: cover;
        position: relative;
        h1{
            width: 780px;
            font-weight: 500;
            font-size: 60px;
            line-height: 80px;;
            text-align: center;
            margin: auto;
            color: #fff;
            @include laptop{
                font-size: 53px;
                line-height: 73px;
            }
            @include ipadpro{
                font-size: 40px;
                line-height: 52px;
                width: 580px;
            }
            @include ipad{
                font-size: 35px;
                line-height: 45px;
                width: 480px;
            }
            @include mobile{
                font-size: 25px;
                line-height: 35px;
                width: 296px;
            }
            @include mobile{
                font-size: 22px;
                line-height: 32px;
                width: 280px;
            }
        }
        .bannerBackground{
            position: relative;
            padding: 118px 0;
            .overlay{
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: #000;
                opacity: 0.7;
            }
        }
        .bannerContent{
            position: relative;
            z-index: 2;
            text-align: center;
            .companyLogo{
                width: 240px;
                display: inline-block;
                @include laptop{
                    width: 200px;
                }
                @include ipadpro{
                    width: 100px;
                }
                @include mobile{
                    width: 65px;
                }

            }
        }
        
    }
    .aboutCardBlock{
        background: #E51636;
        padding: 80px 0;
        @include ipad{
            padding: 60px 0;;
        }
        @include mobile{
            padding: 40px 0;
        }
        .card{
            border-radius: 10px;
            border: 0;
            height: 100%;
            @include mobile{
                margin: 0 40px;
            }
            @include small{
                margin: 0;
            }
            img{
                border-radius: 10px 10px 0 0;
            }
            .cardBody{
                padding: 40px 30px;
                @include ipad{
                    padding: 20px;
                }
                
            }
            h3{
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 20px;
                @include ipadpro{
                    font-size: 20px;
                }
            }
            p{
                padding-bottom: 25px;
                @include ipad{
                    padding-bottom: 20px;
                }
            }
        }
    }
    .networkBlock{
        padding:53px 0 80px 0;
        @include mobile{
            padding: 30px 0;
        }
        ul,button,button:hover{
            border-color: #E51636;
        }
        .tabWrapper li{
            padding-left: 0;
            padding-bottom: 0;
        }
        .tabWrapper li::before{
            border: 0;
        }
        li button{
            padding: 7px 52px;
            color: #E51636;
            @include small{
                padding: 5px 30px;
            }
            
        }
        li button.active{
            color: #fff;
            background-color: #E51636;
        }
        .tabContent{
            padding-top: 40px;
            display: block;
            column-count: 2; // value based on how many columns you want the content to split
            column-gap: 150px;
            height: auto; // height of the div as per your need
            column-fill: balance;
            @include ipad{
                column-gap: 80px;
            }
            @include mobile{
                column-gap: 30px;
            }
            @include small{
                column-count: 1;
            }
            p{
                margin-bottom: 30px;
            }
            .highlights{
                padding-top: 30px;
                
            }
            .features{
                display: inline-block;
                margin-bottom: 64px;
                @include small{
                    margin-bottom: 35px;
                }
            }
            
        }
        
    }
    .fullWidthWrapper{
        position: relative;
    }
    .divideWidthImageWrapper{
        display : -webkit-box;
        display : -ms-flexbox;
        display : flex;
        img{
            width: 50%;
        }
    }
    .promoteWrapper{
        padding: 80px 0;
        @include ipad{
            padding: 40px 0;
        }
        .readMore{
            margin-top: 25px;
            @include ipad{
                margin-bottom: 40px;
            }
        }
        ul{
            padding-right: 125px;
            @include ipad{
                padding-right: 0;
            }
            
        }
    }
    .benefitSummary{
        padding: 50px 0 40px 0;
        @include ipad{
            padding: 40px 0;
        }
        .benefitbuttons{
            padding-top: 32px;
            text-align: center;
            button{
                padding: 10px 45px;
                border: 1px solid #010101;
                border-radius: 35px;
                background: none;
                margin-right: 30px;
                margin-bottom: 40px;
                @include ipad{
                    margin-bottom: 20px;
                    margin-right: 20px;
                }
                @include small{
                    width: 100%;
                }
            }
        }
    }
    .jobContainer{
        padding-bottom: 80px;
        @include mobile{
            padding-bottom: 50px;
        }
        
        .job{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 37px 67px;
            border-radius: 10px;
            background: #F6F6F6;
            @include ipad{
                padding: 30px;
            }
            @include mobile{
                display: block;
                padding: 30px;
            }
            h2{
                @include ipad{
                    font-size: 17px;
                }
                @include mobile{
                    margin: 0;
                }
            }
            .salary,.location{
                text-align: center;
            }
            td{
                @include mobile{
                    width: 100% !important;
                    display: block;
                    text-align: center;
                    padding: 10px 0;
                }
            }
        }
        .companyLogo{
            width: 155px;
            margin-right: 39px;
            display: inline-block;
            @include ipadpro{
                width: 135px;
            }
            @include ipad{
                width: 90px;
                margin-right: 25px;
            }
            @include mobile{
                margin-right: 0;
                width: 125px;
            }
            @include small{
                width: 100px;
            }
        }
        .applyNow{
            padding: 9px 40px;
            background-color: #E51636;
            color: #fff;
            border-radius: 10px;;
            @include ipad{
                padding: 10px 20px;
            }
        }
        
    }
    

}