@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.customDropdown {
    position: relative;
    display: inline-block;
    width: 100%;

    label {
        border-radius: 5PX;
        display: inline-block;
        padding: 10px 28px;
        margin: 0px 5px 0px 5px;
        background-color: var(--primary-color);
        color: #fff;
        cursor: pointer;
    }

    button {
        position: relative;

        .arrowIcon {
            padding-left: 10px;
        }

        @include small {
            padding: unset;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: inline-block;
        max-height: 40vh;
        overflow-y: auto;
        z-index: 1;
        width: 95%;
        border-radius: 5px;
        margin: 5px 5px 0px 5px;
        text-align: center;

        li {
            width: 100%;
            padding: 10px;
            cursor: pointer;
            border: 1px solid #d3d1d1;
            text-align: left;

            &:hover {
                background-color: rgb(211, 209, 209);
            }
        }

        .activeItem {
            background-color: var(--primary-color);
            color: #ffff;
        }
    }

    @include small {
        margin: 0px 5px;
    }
}

.checkIcon {
    margin-right: 10px;
}