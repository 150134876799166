@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.trendSecWrapper {
    background-color: var(--secondary-color);
    padding: 4.5*$ref-size 0 4.5*$ref-size ;
    position: relative;
    z-index: 1;

    @include mobile {
        padding: 4*$ref-size 0 2*$ref-size ;
    }

    h2 {
        margin-top: 0;
    }

    .jobLinksWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0 -6px;

        .linkWrap {
            flex-basis: 20%;
            max-width: 20%;
            width: 100%;
            padding: 0 6px 32px;

            @include ipadpro {
                flex-basis: 25%;
                max-width: 25%;
                padding-bottom: 20px;
            }

            @include ipad {
                flex-basis: 33.33%;
                max-width: 33.33%;
            }

            @include mobile {
                flex-basis: 50%;
                max-width: 50%;
            }

            .link {
                width: 100%;
                background-color: var(--secondary-color);
                border: 1px solid #ffff;
                color: #fff;
                box-shadow: none !important;
                padding: 15px 10px;

                &:hover {
                    background-color: #fff;
                    color: var(--secondary-color);
                }
            }
        }
    }
}